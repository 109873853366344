import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';

export const createProject = async body => {
  const HEADERS = {
    accept: '*/*',
    'Content-Type': 'application/json'
  };

  const requestObj = {
    method: 'post',
    url: `${getFullEndpoint({ microservice: 'project' })}/create`,
    headers: HEADERS,
    data: body
  };

  const response = await axios.request(requestObj);

  return response?.data;
};

export const getProjectById = async (projectId, salesManagerId) => {
  const HEADERS = {
    accept: '*/*'
  };

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'project' })}/getProject?projectId=${projectId}&salesManagerId=${salesManagerId}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj);

  return response?.data;
};

export const getProjectsBySalesManagerAndProject = async (salesManagerId, project) => {
  const HEADERS = {
    accept: '*/*'
  };

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'project' })}/listAll?salesManagerId=${salesManagerId}&project=${project}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj);

  return response?.data;
};

export const updateProject = async (data, beSpokeId) => {
  const HEADERS = {
    accept: '*/*',
    'Content-Type': 'application/json'
  };

  const requestObj = {
    method: 'put',
    url: `${getFullEndpoint({ microservice: 'project' })}/update/${beSpokeId}`,
    headers: HEADERS,
    data
  };

  const response = await axios.request(requestObj);

  return response?.data;
};

export const updatePreferences = async (data) => {
  const HEADERS = {
    accept: '*/*'
  };

  const requestObj = {
    method: 'put',
    url: `${getFullEndpoint({ microservice: 'project' })}/updatePreferences`,
    headers: HEADERS,
    data
  };

  const response = await axios.request(requestObj);
  return response.data;
}