import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';

export const getPlots = async token => {
  const HEADERS = {
    accept: '*/*',
    Authorization: `Bearer ${token}`
  };
  const requestObj = {
    method: 'get',
    url: getFullEndpoint({ microservice: 'plots', service: 'sfSync' }),
    headers: HEADERS
  };

  const response = await axios.request(requestObj).catch(e => console.log(e));
  // const response = await axios.request(requestObj).catch(error => handleError(error));
  return response.data;
};
