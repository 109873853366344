import axios from 'axios';
import { trackingActions } from './tracking-slice';
import { BACKEND_URL } from 'config';

export const updateTracking = (dispatch, idToUpdate, updatedObject) => {
  switch (idToUpdate) {
    case 'nrRooms':
      dispatch(trackingActions.updateNrRooms({ idToUpdate, updatedObject }));
      break;
    case 'houseType':
      dispatch(trackingActions.updateHouseType({ idToUpdate, updatedObject }));
      break;
    case 'houseLayout':
      dispatch(trackingActions.updateHouseLayout({ idToUpdate, updatedObject }));
      break;
    case 'houseName':
      dispatch(trackingActions.updateHouseName({ idToUpdate, updatedObject }));
      break;
    case 'plotSelectedOnImageContainer':
      dispatch(trackingActions.updateWasSelectedOnImageContainer({ updatedObject }));
      break;
    default:
      dispatch(trackingActions.updateTracking({ idToUpdate, updatedObject }));
      break;
  }
};

export const setRecommendation = (dispatch, recommendation) => {
  dispatch(trackingActions.setRecommendation({ recommendation }));
};

export const setPlotHover = (dispatch, plotId) => {
  dispatch(trackingActions.setPlotHover({ plotId }));
};

export const setMacroplotHover = (dispatch, macroplotId) => {
  dispatch(trackingActions.setMacroplotHover({ macroplotId }));
};

export const setResetForm = ({ dispatch, reset, trigger, after }) => {
  dispatch(trackingActions.setResetForm({ reset, trigger, after }));
};

export const updateCurrentStepId = (dispatch, nextStepId) => {
  dispatch(trackingActions.updateCurrentStepId({ nextStepId }));
};

export const updateCurrentQuestionId = (dispatch, currentQuestionId) => {
  dispatch(trackingActions.updateCurrentQuestionId({ currentQuestionId }));
};

export const setInteriorsAreas = async (dispatch, houseTypeId) => {
  const response = await axios.get(`${BACKEND_URL}/houses?id=${houseTypeId}`);
  dispatch(trackingActions.updateInteriorsAreas({ interiorsAreas: response.data }));
};

export const setDialog = (dispatch, open, type, id) => {
  dispatch(trackingActions.setDialog({ open, type, id }));
};

export const resetTracking = dispatch => {
  dispatch(trackingActions.reset());
};
