import { isEmpty } from '@utils/functions';
import useRedux from './useRedux';

const useFilters = () => {
  const { getPlots, getHouses, getMacroplots, getHouseTypes, getModals } = useRedux();

  const getHouseLayoutsByPlotTypologyHouseType = (selectedPlot, selectedNrRooms, selectedHouseType) => {
    const plotHouses = getPlots().find(p => p.id === selectedPlot).houses;
    const filteredHouses = getHouses().filter(house => plotHouses.includes(house.id));
    const houseLayouts = filteredHouses.filter(
      h => h.typology === selectedNrRooms && h.houseType === selectedHouseType
    );
    return houseLayouts;
  };

  const getHouseTypeByPlotAndTypology = (selectedPlot, typology) => {
    // Get matchingHouses from selectedPlot
    // HERE
    const matchingHouses = getPlots().find(plot => plot.id === selectedPlot).houses;

    // Filter matchingHouses according selectedPlot ID
    const filteredHouses = getHouses().filter(house => matchingHouses.includes(house.id));

    // Filter filteredHouses based on selectedTypology, and Get House Types (excluding duplicates)
    const houseTypes = [
      ...new Set(filteredHouses.filter(house => house.typology === typology).map(house => house.houseType))
    ];
    return houseTypes;
  };

  const getHousesByTypology = typology => {
    const filteredHouses = getHouses().filter(house => house.typology === typology);
    return filteredHouses;
  };

  const getHousesById = houseId => {
    const filteredHouses = getHouses().find(house => house.id === houseId);
    return filteredHouses;
  };

  const getMacroplotByPlotId = plotId => {
    console.log('plotId', plotId);
    for (let macroplot of getMacroplots()) {
      if (macroplot.plots.includes(plotId)) {
        return macroplot;
      }
    }
    return null;
  };

  const getPlotsByMacroplotId = macroplotId => {
    const plotsIds = getMacroplots().find(macroplot => macroplot.id === macroplotId).plots;
    return getPlots().filter(plot => plotsIds.includes(plot.id));
  };

  const getPlotsFiltered = (filters, plots) => {
    if (isEmpty(filters)) return plots;

    // filter plots based on filters (Journey + FiltersSorting)
    const houses = getHouses();
    const housesByTypology = houses.filter(house => filters?.bedrooms?.includes(house.typology));
    const housesByHouseType = houses.filter(house => filters?.houseType?.includes(house.houseType));
    const housesByTypologyAndHouseType = housesByTypology.filter(houseByTypology =>
      housesByHouseType.some(houseByHouseType => houseByHouseType.id === houseByTypology.id)
    );

    // filter by area
    if (filters?.plotMinArea !== 'Min') {
      plots = plots.filter(plot => plot.area >= filters.plotMinArea);
    }
    if (filters?.plotMaxArea !== 'Max') {
      plots = plots.filter(plot => plot.area <= filters.plotMaxArea);
    }

    // filter by typology
    if (filters?.bedrooms?.length > 0 && filters?.houseType?.length === 0) {
      plots = plots.filter(plot => housesByTypology.some(house => plot.houses.includes(house.id)));
    }

    // filter by house type
    else if (filters?.houseType?.length > 0 && filters?.bedrooms?.length === 0) {
      plots = plots.filter(plot => housesByHouseType.some(house => plot.houses.includes(house.id)));
    }

    // filter by typology and house type
    else if (filters?.bedrooms?.length > 0 && filters?.houseType?.length > 0) {
      plots = plots.filter(plot => housesByTypologyAndHouseType.some(house => plot.houses.includes(house.id)));
    }

    if (filters?.sortBy === 'Size (Ascending Order)') plots = [...plots].sort((a, b) => a.area - b.area);
    else if (filters?.sortBy === 'Size (Descending Order)') plots = [...plots].sort((a, b) => b.area - a.area);

    return plots;
  };

  const getPlotsByPlotArea = (filters, plots) => {
    let plotsByArea = [];
    if (filters?.plotMinArea !== 'Min' && filters?.plotMaxArea === 'Max') {
      plotsByArea = plots.filter(plot => plot.area >= filters?.plotMinArea);
    } else if (filters?.plotMaxArea !== 'Max' && filters?.plotMinArea === 'Min') {
      plotsByArea = plots.filter(plot => plot.area <= filters?.plotMaxArea);
    } else if (filters?.plotMinArea !== 'Min' && filters?.plotMaxArea !== 'Max') {
      plotsByArea = plots.filter(plot => plot.area >= filters?.plotMinArea);
      plotsByArea = plotsByArea.filter(plot => plot.area <= filters?.plotMaxArea);
    }
    return plotsByArea;
  };

  const getModalsByHouseType = (houseTypes, houseTypeSelected) => {
    let modals = getModals();
    const filteredHouseTypes = getHouseTypes().filter(houseType => houseTypes.includes(houseType.id));

    // find modals that match the available house types
    modals = modals.filter(modal => modal.id === 'house-types')[0].content;
    modals = modals.filter(modal => filteredHouseTypes.some(houseType => houseType.label === modal.title));

    // sort modals based on the selected house type
    const selected = getHouseTypes().filter(houseType => houseType.id === houseTypeSelected)[0].label;
    const itemIndex = modals.findIndex(modal => modal.title === selected);
    modals = [modals[itemIndex], ...modals.filter((_, index) => index !== itemIndex)];

    return modals;
  };

  const getModalsByDivision = (packsDetails, division) => {
    let modals = getModals();
    modals = modals.filter(modal => modal.id === 'theme-details')[0].content;
    modals = modals.filter(modal => packsDetails.some(pack => pack.label === modal.title));
    const itemIndex = modals.findIndex(modal => modal.title === division);
    modals = [modals[itemIndex], ...modals.filter((_, index) => index !== itemIndex)];
    return modals;
  };

  return {
    getHouseLayoutsByPlotTypologyHouseType,
    getHouseTypeByPlotAndTypology,
    getHousesByTypology,
    getMacroplotByPlotId,
    getPlotsByMacroplotId,
    getPlotsFiltered,
    getPlotsByPlotArea,
    getHousesById,
    getModalsByHouseType,
    getModalsByDivision
  };
};

export default useFilters;
