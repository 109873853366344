import { useEffect, useState } from 'react';
// hooks
import useRedux from '@hooks/useRedux';

const useModals = () => {
  const { dispatchSetDialog, getTracking } = useRedux();
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (getTracking().dialog.open) setModalOpen(true);
  }, [getTracking().dialog.open]);

  // handle 'esc' key press
  useEffect(() => {
    const handleEscKey = event => {
      if (event.key === 'Escape') {
        setModalOpen(!isModalOpen);
        if (getTracking().dialog.open) dispatchSetDialog({ open: false, type: '', id: '' });
      }
    };
    if (isModalOpen) window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [isModalOpen]);

  // handle outside click
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        !event.target.closest('.swiper-slide.swiper-slide-visible.swiper-slide-fully-visible') &&
        !event.target.closest('.swiper-pagination') &&
        !event.target.closest('.dialog') &&
        !event.target.closest('.modal-left-button') &&
        !event.target.closest('.modal-right-button')
      ) {
        setModalOpen(!isModalOpen);
        if (getTracking().dialog.open) dispatchSetDialog({ open: false, type: '', id: '' });
      }
    };
    if (isModalOpen) window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  return [isModalOpen, setModalOpen];
};

export default useModals;
