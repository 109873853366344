import { openDB } from 'idb';

const dbPromise = openDB('video-storage', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('videos')) {
      db.createObjectStore('videos');
    }
  }
});

async function deleteVideoFromCache(videoId) {
  const db = await dbPromise;
  await db.delete('videos', videoId);
}

async function saveVideoToCache(videoId, videoBlob, metaData, metaDataId) {
  const db = await dbPromise;
  await db.put('videos', videoBlob, videoId);
  await db.put('videos', metaData, metaDataId);
}

async function getElementOnIndexedDB(elementId) {
  const db = await dbPromise;
  return await db.get('videos', elementId);
}

export async function fetchVideo(videoId, url, metaData, metaDataId) {
  const cachedVideo = await getElementOnIndexedDB(videoId);
  const metaDataOnDB = await getElementOnIndexedDB(metaDataId);

  if (cachedVideo && metaDataOnDB) {
    if (metaData.updatedAt === metaDataOnDB.updatedAt || url === metaDataOnDB.url ) {
      console.log('video cached', cachedVideo);
      return URL.createObjectURL(cachedVideo);
    } else {
      console.log('video is different from the cached one');
      deleteVideoFromCache(videoId);
    }
  } else {
    deleteVideoFromCache(videoId);
  }

  const response = await fetch(url);
  const videoBlob = await response.blob();
  saveVideoToCache(videoId, videoBlob, metaData, metaDataId);

  if (videoBlob instanceof Blob) {
    const blobUrl = URL.createObjectURL(videoBlob);
    return blobUrl;
  } else {
    console.error('O conteúdo recuperado não é um Blob válido:', videoBlob);
    throw new Error('Conteúdo inválido no cache.');
  }
}
