// styles
import { SVG } from '@assets/svg';
import './ZoomControls.scss';

const ZoomControls = ({ zoomIn, zoomOut }) => {
  return (
    <div className='zoom-controls'>
      <button className='zoom-controls-button-plus' onClick={zoomIn}>
        <SVG icon='semantic/plus' />
      </button>
      <span className='zoom-controls-divider' />
      <button className='zoom-controls-button-minus' onClick={zoomOut}>
        <SVG icon='semantic/minus' />
      </button>
    </div>
  );
};

export default ZoomControls;
