import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  language: null,
  currency: 'Euro',
  units: 'Metric System'
};

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: INITIAL_STATE,
  reducers: {
    updateLanguage: (state, action) => {
      const { language } = action.payload;
      state.language = language;
    },
    updateCurrency: (state, action) => {
      const { currency } = action.payload;
      state.currency = currency;
    },
    updateUnits: (state, action) => {
      const { units } = action.payload;
      state.units = units;
    },
    setLanguageOptions: (state, action) => {
      const { languageOptions } = action.payload;
      state.languageOptions = languageOptions;
    },
    reset: state => {
      state.currency = INITIAL_STATE.currency;
      state.units = INITIAL_STATE.units;
    }
  }
});

const preferencesActions = preferencesSlice.actions;
export { preferencesSlice, preferencesActions };
