import { getProjectSubmittedContent } from '@services/singlePages/projectSubmitted';
import { projectSubmittedAction } from './projectSubmitted-slice';

export const setProjectSubmittedContent = async dispatch => {
  try {
    const response = await getProjectSubmittedContent();
    dispatch(projectSubmittedAction.setProjectSubmittedContent(response));
  } catch (error) {
    console.log('ERROR', error);
  } finally {
  }
};
