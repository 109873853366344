import { authAction } from './auth-slice';

export const setIsAuthenticated = async (dispatch, boolean) => {
  try {
    dispatch(authAction.setIsAuthenticated(boolean));
  } catch (error) {
    console.log('ERROR setting auth on redux', error);
  } finally {
  }
};
