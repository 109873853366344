import { useEffect, useState } from 'react';
// components
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
// styles
import './Slideshow.scss';

/**
 * Slideshow component to display a series of images with optional auto-sliding functionality.
 *
 * @param {string[]} images - Array of image URLs to display in the slideshow.
 * @param {boolean} [autoSlide=false] - Flag to enable or disable automatic sliding of images.
 *
 * @returns {JSX.Element} The rendered Slideshow component.
 */
const Slideshow = ({ images, autoSlide = false }) => {
  // slide show component
  const interval = 5000;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const resetInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }

    if (autoSlide) {
      const newIntervalId = setInterval(() => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
      }, interval);
      setIntervalId(newIntervalId);
    }
  };

  const prevImage = () => {
    setCurrentImageIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
    resetInterval();
  };

  const nextImage = () => {
    setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    resetInterval();
  };

  useEffect(() => {
    resetInterval();

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [images?.length, autoSlide]); // Listen to autoSlide

  return (
    <div className='slideshow'>
      <div className='left-button'>
        <IconButton size={'default'} onImageBg={true} icon={'navigation/arrow-left'} onClickFn={prevImage} />
      </div>
      <img src={images?.[currentImageIndex]} alt='' />
      <div className='right-button'>
        <IconButton size={'default'} onImageBg={true} icon={'navigation/arrow-right'} onClickFn={nextImage} />
      </div>
    </div>
  );
};

export default Slideshow;
