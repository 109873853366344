import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SVG } from '@assets/svg';
import Button from '@components/ui/Buttons/Button/Button';
import logoImage from '../../assets/img/logo_bespoke_gray.png';
import useRedux from '@hooks/useRedux';
import { useNavigate } from 'react-router-dom';

const DashboardProjectsComponent = ({
  isCollapsed,
  selectedClientIndex,
  projectsList,
  handleProjectClick,
  houseTypesInfo,
  unfilteredProjects,
  clientsList
}) => {
  const { t } = useTranslation();
  const [loadedImages, setLoadedImages] = useState({});
  const { dispatchClient } = useRedux();

  const handleImageLoad = index => {
    setLoadedImages(prevState => ({
      ...prevState,
      [index]: true
    }));
  };
  const navigate = useNavigate();

  return (
    <div className={`projects-section ${isCollapsed ? 'full-width' : ''}`}>
      {(selectedClientIndex || selectedClientIndex === 0) && (
        <div className='new-design-card'>
          <div className='new-design-content'>
            <SVG icon='logo/bespokeliving_grey' />
            <div className='new-design-button'>
              <Button
                onClickFn={() => {
                  navigate('/enterprise-selection');
                  dispatchClient({ email: clientsList?.[selectedClientIndex]?.email?.value });
                }}
                primary
                text={t('dashboard.start_new_design')}
              />
            </div>
          </div>
        </div>
      )}
      {unfilteredProjects.map((project, index) => {
        const hasLayoutImage = houseTypesInfo?.find(el => el?.label === project?.layout?.value)?.image;
        const imageSrc = hasLayoutImage ? project.image : logoImage;
        const isImageLoaded = loadedImages[index];

        return (
          <div
            key={index}
            style={{ display: projectsList.includes(project) ? 'block' : 'none' }}
            className='project-card'
            onClick={() => handleProjectClick(project.bespokeId?.value)}
          >
            <div className='image-wrapper'>
              <img
                style={{
                  objectFit: isImageLoaded && hasLayoutImage ? 'cover' : 'none'
                }}
                src={imageSrc}
                alt='layout-image'
                className='project-image'
                onLoad={() => {
                  if (hasLayoutImage) handleImageLoad(index);
                }}
              />
            </div>
            <div className='project-info'>
              <span className='project-name'>{project.project}</span>
              {Object.entries(project).map(([key, value]) => {
                if (key === 'image' || key === 'project' || key === 'clientId') return null;
                return (
                  <div key={key} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span className='project-info-key'>
                      {value?.label?.charAt(0)?.toUpperCase() + value?.label?.slice(1)}
                    </span>
                    <span className='project-info-value'>{value?.value ?? '-'}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DashboardProjectsComponent;
