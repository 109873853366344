import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {},
  reducers: {
    setDashboardContent: (state, action) => {
      return { ...action.payload };
    }
  }
});

const dashboardAction = dashboardSlice.actions;
export { dashboardSlice, dashboardAction };
