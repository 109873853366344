import { useState, useEffect } from 'react';
//components
import Header from '@components/ui/Header/Header';
// hooks
import useRedux from '@hooks/useRedux';
//styles
import './infoPages.scss';

const InfoPageComponent = ({ data }) => {
  const [imageIsReady, setImageIsReady] = useState(false);
  const [content, setContent] = useState(null);
  const { PREFERENCES } = useRedux();

  useEffect(() => {
    if (PREFERENCES.get().languageOptions && PREFERENCES.get().language === null)
      PREFERENCES.updateLanguage(PREFERENCES.get().languageOptions.find(option => option.isDefault));
    if (PREFERENCES.get().language !== null) setContent(data?.[PREFERENCES?.get()?.language?.code]);
  }, [PREFERENCES]);

  return (
    <>
      <div className='info-container'>
        <Header shouldDisplay />
        <div className='image-wrapper'>
          <img
            onLoad={() => {
              setImageIsReady(true);
            }}
            className='background-image'
            src={content?.pageImage}
            alt={content?.pageTitle || 'Background image'}
          />
          <span className='page-title'>{content?.pageTitle}</span>
        </div>

        {imageIsReady && (
          <div className='text-wrapper'>
            <span dangerouslySetInnerHTML={{ __html: content?.bodycopy }} />
          </div>
        )}
      </div>
    </>
  );
};

export default InfoPageComponent;
