import { BACKEND_URL } from '../../config';

export const ENDPOINTS = {
  FORMS: `${BACKEND_URL}/forms`,
  MACROPLOTS: `${BACKEND_URL}/macroplots`,
  PLOTS: `${BACKEND_URL}/plots`,
  HOUSES: `${BACKEND_URL}/houses`,
  IMAGES: `${BACKEND_URL}/images`,
  MODULES: `${BACKEND_URL}/modules`,
  HOUSETYPES: `${BACKEND_URL}/houseTypes`,
  PACKS: `${BACKEND_URL}/packs`,
  SVG: `${BACKEND_URL}/svg`,
  MODALS: `${BACKEND_URL}/modals`,
  PARTNERS: `${BACKEND_URL}/partners`,
  SALESMANAGER: `${BACKEND_URL}/sales-manager`,
  CLIENT: `${BACKEND_URL}/client`,
  INFOPAGES: `${BACKEND_URL}/info-pages`,
  HOMEPAGE: `${BACKEND_URL}/homepage`,
  PRICING: `${BACKEND_URL}/pricing`,
  PROJECTSUBMITTED: `${BACKEND_URL}/project-submitted`,
  PROJECT: `${BACKEND_URL}/project`,
  OTP: `${BACKEND_URL}/otp`,
  EMAIL: `${BACKEND_URL}/email`,
  DASHBOARD: `${BACKEND_URL}/dashboard`,
  ENTERPRISE: `${BACKEND_URL}/enterprise`,
  STRAPI: `${BACKEND_URL}/strapi`
};

const PLOTS = {
  sfSync: 'sf-sync'
};

const SALESMANAGER = {
  login: 'login'
};

const CLIENT = {
  getDetails: 'getDetails',
  registerClient: 'createClient',
  getClientOnDb: 'getClientOnDb',
  createRequest: 'createRequest',
  getAllClients: 'getAllClients'
};

const EMAIL = {
  requestContact: 'requestContact'
};

const OTP = {
  sendVerificationCode: 'sendVerificationCode',
  checkVerificationCode: 'checkVerificationCode'
};

const ENTERPRISE = {
  getImages: 'getImages'
};

const STRAPI = {
  updateCollections: 'updateCollections'
};

export const SERVICES_POINTS = {
  PLOTS,
  SALESMANAGER,
  CLIENT,
  OTP,
  EMAIL,
  ENTERPRISE,
  STRAPI
};
