import { preferencesActions } from './preferences-slice';

export const updateLanguage = ({ dispatch, language }) => {
  dispatch(preferencesActions.updateLanguage({ language }));
};

export const updateCurrency = (dispatch, currency) => {
  dispatch(preferencesActions.updateCurrency({ currency }));
};

export const updateUnits = (dispatch, units) => {
  dispatch(preferencesActions.updateUnits({ units }));
};

export const setLanguageOptions = (dispatch, languageOptions) => {
  dispatch(preferencesActions.setLanguageOptions({ languageOptions }));
};

export const resetPreferences = dispatch => {
  dispatch(preferencesActions.reset());
};
