import { useState, useEffect } from 'react';
// components
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import Button from '@components/ui/Buttons/Button/Button';
import ProfileCell from '@components/ui/Cells/ProfileCell/ProfileCell';
import Tag from '@components/ui/Tag/Tag';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
// hooks
import useRedux from '@hooks/useRedux';
// styles
import './MultiChoiceModal.scss';
//services
import { sendEmail } from '@services/emails';
//translations
import { useTranslation } from 'react-i18next';
//utils
import { MODAL_TYPE } from '@utils/enums';

const MultiChoiceModal = ({ onClose }) => {
  const { getModals, dispatchNotification, getCurrentProject } = useRedux();
  const [selectedTag, setSelectedTag] = useState('All');
  let profiles = getModals().find(modal => modal.id === MODAL_TYPE.DesignExperts)?.content?.choices;
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();

  let tags = ['All'];
  profiles?.map(profile => {
    if (!tags.includes(profile?.service)) tags.push(profile?.service);
  });

  profiles = profiles.filter(profile => profile?.service === selectedTag || selectedTag === 'All');

  const handleRequestContactButton = async () => {
    if (selected.length > 0) {
      try {
        const payloadToSend = {
          to: 'joaotalegria@gmail.com',
          identifier: 'additional-services',
          projectName: getCurrentProject()?.configurationName ?? getCurrentProject()?.configurationDefaultName,
          bespokeId: getCurrentProject()?.id,
          clientName: getCurrentProject()?.clientId?.name,
          clientEmail: getCurrentProject()?.clientId?.email,
          services: selected.map(item => ({
            type: item?.service,
            name: item.name
          }))
        };

        sendEmail(payloadToSend);
        onClose();
        dispatchNotification({
          status: 'success',
          title: t('requestContact.success')
        });
      } catch (error) {
        console.log('Error', error);
        onClose();
        dispatchNotification({
          status: 'error',
          title: t('requestContact.error')
        });
      }
    }
  };

  const buttonActions = {
    EXIT: onClose,
    'SEND-EMAIL': handleRequestContactButton
  };

  return (
    <Swiper
      watchSlidesProgress={true} // enables to see next or previous slide
      slidesPerView={'auto'} // max os slides per view
      centeredSlides={true}
      spaceBetween={40}
      pagination={{
        clickable: true,
        dynamicBullets: false
      }}
      modules={[Navigation, Pagination]}
      className='multi-choice-modal-swiper'
    >
      <SwiperSlide className='multi-choice-modal-swiper-slide'>
        <div className='multi-choice-modal-slide-content'>
          <div className='multi-choice-modal-slide-text'>
            <div className='multi-choice-modal-text-header'>
              <IconButton
                className='multi-choice-modal-close'
                size={'small'}
                onImageBg={false}
                icon={'semantic/close'}
                onClickFn={onClose}
              />
            </div>
            <span className='multi-choice-modal-title'>
              {getModals().find(modal => modal.id === MODAL_TYPE.DesignExperts)?.content?.title}
            </span>
            <span className='multi-choice-modal-description'>
              {getModals().find(modal => modal.id === MODAL_TYPE.DesignExperts)?.content?.descriptions}
            </span>
          </div>
          <div className='multi-choice-modal-content'>
            <div className='multi-choice-modal-content-header'>
              <div className='multi-choice-modal-tag'>
                {tags.map(tag => {
                  return <Tag label={tag} selected={selectedTag} onClickFn={() => setSelectedTag(tag)}></Tag>;
                })}
              </div>
              <LinkButton text='Deselect all' onClickFn={() => setSelected([])}></LinkButton>
            </div>
            <div className='multi-choice-modal-profile'>
              {profiles.map(profile => {
                return (
                  <ProfileCell
                    profile={profile}
                    onSelect={() => setSelected([...selected, profile])}
                    onDeselect={() => setSelected(selected.filter(item => item !== profile))}
                    isProfileSelected={selected.includes(profile)}
                  ></ProfileCell>
                );
              })}
            </div>
          </div>
          <div className='multi-choice-modal-footer'>
            {getModals()
              .find(modal => modal.id === MODAL_TYPE.DesignExperts)
              ?.content?.actions.map(action => {
                const buttonPropsMap = {
                  primary: {
                    primary: true,
                    isDark: action.type.toLowerCase().includes('dark'),
                    destructive: action.type.toLowerCase().includes('destructive')
                  },
                  secondary: {
                    secondary: true,
                    isDark: action.type.toLowerCase().includes('dark'),
                    destructive: action.type.toLowerCase().includes('destructive')
                  },
                  ghost: {
                    ghost: true,
                    isDark: action.type.toLowerCase().includes('dark'),
                    destructive: action.type.toLowerCase().includes('destructive')
                  }
                };
                const buttonProps = buttonPropsMap[action.type.toLowerCase()?.split('-')?.[0]];

                return (
                  <Button
                    {...buttonProps}
                    onClickFn={() => {
                      buttonActions[action.action] && buttonActions[action.action]();
                    }}
                    text={action.label}
                    key={action.label}
                  />
                );
              })}
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MultiChoiceModal;
