import { createSlice } from '@reduxjs/toolkit';

const projectSubmittedSlice = createSlice({
  name: 'projectSubmitted',
  initialState: {},
  reducers: {
    setProjectSubmittedContent: (state, action) => {
      return { ...action.payload };
    }
  }
});

const projectSubmittedAction = projectSubmittedSlice.actions;
export { projectSubmittedSlice, projectSubmittedAction };
