import './Stepper.scss';
import useRedux from '@hooks/useRedux';
import { SVG } from '../../../assets/svg';
import { useEffect } from 'react';

const Stepper = ({ stepsNames, stepsIds, currentStepNr, onStepClick }) => {
  const { FORMSTATE } = useRedux();
  let stepsFinished = FORMSTATE.get('stepsFinished');
  const stepChanged = FORMSTATE.get('stepChanged');

  const getStepperClassName = (currentStepNr, index) => {
    if (currentStepNr === index) return 'active';
  };

  useEffect(() => {
    if (stepChanged) {
      if (stepsIds[currentStepNr] === 'plot') {
        stepsFinished = [];
        FORMSTATE.dispatchUpdateStepsFinished(stepsFinished);
      } else if (stepsIds[currentStepNr] === 'layout') {
        stepsFinished = ['plot'];
        FORMSTATE.dispatchUpdateStepsFinished([]);
        FORMSTATE.dispatchUpdateStepsFinished(stepsFinished);
      } else {
        stepsFinished = stepsFinished.filter(step => step !== stepsIds[currentStepNr] && step !== 'review');
        FORMSTATE.dispatchUpdateStepsFinished([]);
        FORMSTATE.dispatchUpdateStepsFinished(stepsFinished);
      }
    }
  }, [stepChanged]);

  return (
    <div className='stepper'>
      {stepsNames?.map((step, index) => (
        <div
          className={getStepperClassName(currentStepNr, index)}
          key={`stepper-${index}`}
          onClick={() => {
            // Only allows to navigate by stepper if index of stepper is lower than currentStep
            if (index < currentStepNr || stepsFinished.includes(stepsIds[index])) {
              onStepClick(index, stepsFinished);
            }
          }}
        >
          {stepsFinished.includes(stepsIds[index]) && stepsIds[index] !== 'review' && (
            <SVG icon='semantic/check-circle' />
          )}
          {step}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
