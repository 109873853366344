import { useState } from 'react';
import { useParams } from 'react-router-dom';
// components
import Header from '@components/ui/Header/Header';
// styles
import './ServicesPartnersPage.scss';
// hooks
import useRedux from '@hooks/useRedux';
import { useEffect } from 'react';
import { isEmpty } from '@utils/functions';

const ServicesPartnersPage = () => {
  const { getPartners } = useRedux();
  const { partnerId } = useParams();
  const partners = getPartners();
  const [partner, setPartner] = useState({});

  useEffect(() => {
    if (!isEmpty(partners)) setPartner(partners.find(partner => partner.id === partnerId));
  }, [partners]);

  return (
    <div className='page-background'>
      <Header isDark shouldDisplay={true} noLogin={true} />
      <div div className='services-partner'>
        <div className='services-partner-content'>
          <span className='services-partner-content-name'>{partner.service_label}</span>
          <div className='services-partner-content-details'>
            <img src={partner.image} alt='' />
            <div className='services-partner-content-details-service'>
              {partner.service && (
                <span className='services-partner-content-details-service-title'>{partner.service}</span>
              )}
              <span className='services-partner-content-details-service-description'>{partner.bodycopy}</span>
            </div>
          </div>
        </div>
        {partner?.quote && (
          <div className='services-partner-quote'>
            <span>{partner?.quote}</span>
          </div>
        )}
        {partner.project_images !== null && (
          <div className='services-partner-projects'>
            <span>{partner.projects_label}</span>
            <div className='services-partner-projects-images'>
              {partner.project_images?.map(image => {
                return <img src={image} alt='' />;
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServicesPartnersPage;
