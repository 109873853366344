import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
// components
import Header from '@components/ui/Header/Header';
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import RadioButton from '@components/ui/RadioButton/RadioButton';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
import Button from '@components/ui/Buttons/Button/Button';
import InputPhone from '@components/ui/Inputs/PhoneInput/InputPhone';
// hooks
import useRedux from '@hooks/useRedux';
// styles
import './ClientOTPRequest.scss';
// utils
import image from '../Client/image.png';
import { isEmpty, isValidEmail } from '@utils/functions';
//services
import { sendVerificationCode } from '@services/otp';

const ClientOTPRequest = () => {
  const navigate = useNavigate();
  const { getClient, dispatchClient } = useRedux();
  const [goToNextPage, setGoToNextPage] = useState(false);

  const [error, setError] = useState({});
  const [contactMethod, setContactMethod] = useState('E-mail');
  const [clientInfo, setClientInfo] = useState({
    email: getClient().email,
    phone: getClient().phone,
    whatsapp: getClient().phone,
  });

  // Handle input changes
  const handleChange = e => {
    const { name, value } = e.target;
    setClientInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleClear = e => {
    setClientInfo(prev => ({ ...prev, [e.target.name]: '' }));
  };

  useEffect(() => {
    if (goToNextPage && isEmpty(error)) {
      sendVerificationCodeFunction();
      navigate('/client/verify');
    }
    setGoToNextPage(false);
  }, [goToNextPage, error]);

  const handleSend = () => {
    setError({});
    if (contactMethod === 'E-mail' && isEmpty(clientInfo?.email))
      setError(prev => ({ ...prev, email: 'Please enter an email address.' }));

    if ((contactMethod === 'Phone number' || contactMethod === 'Whatsapp') && !isEmpty(clientInfo?.phone) && !validator.isMobilePhone(clientInfo.phone))
      setError(prev => ({ ...prev, phone: 'Please enter a valid phone number.' }));
    if (contactMethod === 'E-mail' && !isEmpty(clientInfo?.email) && !isValidEmail(clientInfo?.email))
      setError(prev => ({ ...prev, email: 'Please enter a valid email address.' }));

    setGoToNextPage(true);
  };

  useEffect(() => {
    if (!isEmpty(clientInfo?.phone)) setError(prev => ({ ...prev, phone: '' }));
    if (!isEmpty(clientInfo?.email)) setError(prev => ({ ...prev, email: '' }));
  }, [clientInfo]);

  const sendVerificationCodeFunction = async () => {
    if (contactMethod === 'E-mail') {
      try {
        const updateClient = { ...getClient(), otpContactService: {contact: clientInfo?.email, method: 'email'} };
        dispatchClient(updateClient);
        await sendVerificationCode(clientInfo?.email, 'email');
      } catch (error) {
        console.log('Error sending verification code', error);
      }
    } else if (contactMethod === 'Phone number') {
      try {
        const updateClient = { ...getClient(), otpContactService: {contact: clientInfo?.phone, method: 'sms'} };
        dispatchClient(updateClient);
        await sendVerificationCode(clientInfo?.phone, 'sms');
      } catch (error) {
        console.log('Error sending verification code', error);
      }
    }
    else if (contactMethod === 'Whatsapp') {
      try {
        const updateClient = { ...getClient(), otpContactService: {contact: clientInfo?.phone, method: 'whatsapp'} };
        dispatchClient(updateClient);
        await sendVerificationCode(clientInfo?.phone, 'whatsapp');
      } catch (error) {
        console.log('Error sending verification code', error);
      }
    }
  };

  return (
    <div className='client-otp-request-container'>
      <Header shouldDisplay isDark />
      <div className='client-otp-request-wrapper'>
        <img src={image} className='client-otp-request-wrapper-background-image' />
        <div className='client-otp-request-content-wrapper'>
          <div className='back-button'>
            <LinkButton
              icon='navigation/arrow-left'
              text='Back'
              onClickFn={() =>
                navigate('/client/register', { state: clientInfo.email })
              } /* substituir pelo body do register client enviado para salesforce */
            />
          </div>
          <div className='client-otp-request-content'>
            <div className='client-otp-request-content-header'>
              <span className='client-otp-request-content-header-title'>Secure your path to personalized luxury</span>
              <span className='client-otp-request-content-header-description'>
                Verify your identity to step into a world of sophistication and customization.
              </span>
            </div>
            <div className='client-otp-request-content-body'>
              <div className='service'>
                <span className='service-title'>Preferred verification service</span>
                <div className='service-list'>
                  {['E-mail', 'Phone number', 'Whatsapp'].map(label => {
                    return (
                      <RadioButton
                        name='brokerage'
                        id={label}
                        label={label}
                        onSelect={() => setContactMethod(label)}
                        value={contactMethod}
                      />
                    );
                  })}
                </div>
              </div>
              {contactMethod === 'E-mail' && (
                <TextInput
                  label='E-mail'
                  name='email'
                  icon='action/email'
                  value={clientInfo.email}
                  onChangeFn={handleChange}
                  clearFn={() => handleClear({ target: { name: 'email' } })}
                  required
                  errorMessage={error.email}
                />
              )}
              {contactMethod === 'Phone number' && (
                <InputPhone
                  name='phone'
                  value={`${clientInfo.phone}`}
                  onChangeFn={item => {
                    setClientInfo(prev => ({ ...prev, phone: item }));
                  }}
                  clearFn={() => handleClear({ target: { name: 'phone' } })}
                  required
                  errorMessage={error.phone}
                />
              )}
                 {contactMethod === 'Whatsapp' && (
                <InputPhone
                  name='Whatsapp'
                  value={`${clientInfo.phone}`}
                  onChangeFn={item => {
                    setClientInfo(prev => ({ ...prev, phone: item }));
                  }}
                  clearFn={() => handleClear({ target: { name: 'phone' } })}
                  required
                  errorMessage={error.phone}
                />
              )}
            </div>
            <div className='client-otp-request-content-footer'>
              <div className='client-otp-request-content-footer-button'>
                <Button
                  primary
                  text={'Send'}
                  onClickFn={() => {
                    handleSend();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientOTPRequest;
