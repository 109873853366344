export const PLOT_STATUS = Object.freeze({
  Sold: 'CPCV' || 'Property Deed',
  Available: 'Available',
  InNegotiation: 'Negotiation',
  Reserved: 'Reserved',
  NotAvailable: 'Not Available',
  NotMatchFilters: "Doesn't match filters"
});

export const DIALOG_TYPE = Object.freeze({
  SaveProject: 'save-project',
  ConsultPrice: 'consult-price',
  SaveExit: 'save-exit',
  SubmitProject: 'submit-project',
});

export const MODAL_TYPE = Object.freeze({
  DesignExperts: 'design-experts'
});

export const ACTIONS = Object.freeze({
  NewDesign: 'NEW-DESIGN',
  Exit: 'EXIT',
  Back: 'BACK',
  ConfirmInterest: 'CONFIRM-INTEREST',
  SendEmail: 'SEND-EMAIL'
});
