import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
//components
import Header from '@components/ui/Header/Header';
import { SVG } from '@assets/svg';
import DashboardProjectsComponent from './DashboardProjectsComponent';
//styles
import './dashboard.scss';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
//hooks
import { useNavigate } from 'react-router-dom';
import useRedux from '@hooks/useRedux';
import { useSearchParams } from 'react-router-dom';
//services
import { getProjectsBySalesManagerAndProject } from '@services/projects';
import { getAllClients, createRequest, getClientDetails } from '@services/client';

const Dashboard = () => {
  const [dataIsReady, setDataIsReady] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedClientIndex, setSelectedClientIndex] = useState(null);
  const [selectedClientObj, setSelectedClientObj] = useState(null);
  const [clientSearch, setClientSearch] = useState('');
  const [clientsList, setClientsList] = useState([]);
  const [formattedClients, setFormattedClients] = useState([]);
  const [formattedProjects, setFormattedProjects] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [unfilteredProjects, setUnfilteredProjects] = useState([]);
  const navigate = useNavigate();
  const {
    dispatchProjectInfo,
    dispatchNotification,
    getHouseTypes,
    dispatchClient,
    PREFERENCES,
    DASHBOARD,
    dispatchResetTracking
  } = useRedux();
  const sales_manager = Cookies.get('salesManager');
  const sales_manager_id = Cookies.get('salesManagerId');
  const sales_manager_token = Cookies.get('sessionId');
  const [searchParams] = useSearchParams();
  const clientIdFromParam = searchParams.get('clientId');
  const houseTypesInfo = getHouseTypes();
  const lang = PREFERENCES.get()?.language?.code;
  const dashboard = DASHBOARD.get()?.[lang];

  useEffect(() => {
    if (clientsList?.length === 0) {
      setSelectedClientIndex(null);
      setSelectedClientObj(null);
      setProjectsList(formattedProjects);
    }

    if (!clientsList.includes(selectedClientObj)) {
      setSelectedClientIndex(null);
      setSelectedClientObj(null);
      setProjectsList(formattedProjects);
    }
  }, [selectedClientIndex, clientsList]);

  useEffect(() => {
    dispatchResetTracking();
    dispatchProjectInfo(null);
  }, []);

  useEffect(() => {
    const setInitialData = async () => {
      const allClients = await getAllClients(sales_manager_id);
      const projectsOfSM = (await getProjectsBySalesManagerAndProject(sales_manager_id, 'TCT')) || [];

      const formattedClientData = allClients.map(item => {
        return {
          name: item.name,
          email: { label: dashboard?.client_email, value: item.email },
          phone: { label: dashboard?.client_phone, value: item.phone },
          nationality: { label: dashboard?.client_nationality, value: item.nationality },
          configurations: {
            label: dashboard?.client_configurations,
            value: projectsOfSM.filter(el => el?.clientId?.id === item.id).length
          },
          id: item.id
        };
      });

      const formatCreatedDate = inputDate => {
        const date = new Date(inputDate);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}/${month}/${year} h ${hours}:${minutes}`;
      };

      const formattedProjectsData = projectsOfSM.map(item => {
        const projectClient = formattedClientData.find(el => el.id === item?.clientId?.id);
        return {
          project: item.configurationName ?? item.configurationDefaultName,
          image:
            houseTypesInfo && houseTypesInfo?.length > 0
              ? houseTypesInfo?.find(el => el?.id === item?.layout)?.image
              : null,
          client: { label: dashboard?.card_client, value: projectClient?.name },
          clientId: projectClient?.id,
          bespokeId: { label: dashboard?.card_bespokeId, value: item.id },
          createdAt: { label: dashboard?.card_date, value: formatCreatedDate(item.createdAt) },
          plot: { label: dashboard?.card_plot, value: item.plot },
          size: { label: dashboard?.card_house, value: item.size },
          layout: {
            label: dashboard?.card_layout,
            value:
              houseTypesInfo && houseTypesInfo.length > 0
                ? houseTypesInfo?.find(el => el?.id === item?.layout)?.label
                : null
          }
        };
      });
      setClientsList(formattedClientData);
      setFormattedClients(formattedClientData);
      setFormattedProjects(formattedProjectsData);
      setProjectsList(formattedProjectsData);
      setUnfilteredProjects(formattedProjectsData);
      if (dashboard && houseTypesInfo && houseTypesInfo.length > 0) {
        setDataIsReady(true);
      }
    };

    setInitialData();
  }, [houseTypesInfo, dashboard]);

  const handleProjectClick = bespokeId => {
    navigate(`/journey/${bespokeId}`);
    const clickedProject = formattedProjects.find(project => project.bespokeId.value === bespokeId);
    const clientEmail = formattedClients.find(client => client.id === clickedProject.clientId).email;
    dispatchClient({ email: clientEmail });
  };

  useEffect(() => {
    if (clientIdFromParam && formattedClients.length > 0) {
      const clientIsAssociatedToSM = formattedClients.some(el => `${el.id}` === clientIdFromParam);
      if (clientIsAssociatedToSM) {
        const clientSelectedOnParam = formattedClients.filter(el => `${el.id}` === clientIdFromParam);
        setClientSearch(clientSelectedOnParam[0].name);
        setClientsList(clientSelectedOnParam);
        setSelectedClientIndex(0);
        setSelectedClientObj(clientSelectedOnParam[0]);
        const filteredProjects = formattedProjects.filter(
          projectData => projectData.client.value === clientSelectedOnParam[0].name
        );
        setProjectsList(filteredProjects);
      } else {
        dispatchNotification({
          timer: true,
          type: 'banner',
          status: 'error',
          title: 'It seems this Client is not assigned to you. Please make an internal request and try again later.'
        });
        navigate('/dashboard');
      }
    }
  }, [formattedClients]);

  useEffect(() => {
    if (clientSearch === '') {
      setClientsList(formattedClients);
      setSelectedClientIndex(null);
      setSelectedClientObj(null);
      return;
    }
  }, [clientSearch]);

  useEffect(() => {
    if (!clientIdFromParam) {
      dispatchClient(null);
    }
  }, []);

  const handleClientSearch = client => {
    setClientSearch(client);

    const normalizeString = str => {
      return str
        ? str
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
        : '';
    };

    const normalizedSearch = normalizeString(client);

    const filteredClients = formattedClients.filter(clientData => {
      const normalizedName = normalizeString(clientData?.name);
      const normalizedEmail = normalizeString(clientData?.[dashboard?.client_email]);
      const normalizedPhone = normalizeString(clientData?.[dashboard?.client_phone]);

      return (
        normalizedName.startsWith(normalizedSearch) ||
        normalizedEmail.startsWith(normalizedSearch) ||
        normalizedPhone.startsWith(normalizedSearch)
      );
    });

    setClientsList(filteredClients);
  };

  const handleClientSelection = (client, index) => {
    if (index === selectedClientIndex) {
      setSelectedClientIndex(null);
      setSelectedClientObj(null);
      dispatchClient(null);
      setProjectsList(formattedProjects);
      setClientSearch('');
      return;
    }

    setSelectedClientIndex(index);
    setSelectedClientObj(clientsList[index]);
    setClientSearch(client.name);
    const filteredProjects = formattedProjects.filter(projectData => projectData.client.value === client.name);
    setProjectsList(filteredProjects);
  };

  const handleClearClients = () => {
    setSelectedClientIndex(null);
    dispatchClient(null);
    setClientSearch('');
    setClientsList(formattedClients);
    setProjectsList(formattedProjects);
  };

  const handleStartDesign = async () => {
    const clientSFInfo = await getClientDetails(clientsList[selectedClientIndex]?.email.value, sales_manager_token);

    await createRequest(
      {
        clientEmail: clientsList[selectedClientIndex]?.email.value,
        salesManagerEmail: sales_manager,
        salesManagerId: sales_manager_id,
        clientDetailsFromSF: clientSFInfo.data,
        projectDefaultLanguage: PREFERENCES?.get()?.language?.code
      },
      sales_manager_token
    )
      .then(async response => {
        if (response) {
          dispatchProjectInfo(response.projectInfo);
          navigate(`/journey/${response.projectInfo.id}`);
        }
      })
      .catch(error => {
        console.log('Error saving project do DB', error);
      });
  };

  return (
    <>
      {dataIsReady ? (
        <div className='dashboard-container'>
          <Header shouldDisplay isDark logoOnly />
          <div className='content-container'>
            <div className='projects-wrapper'>
              <div className='title-section'>
                <span className='title'>{dashboard.title}</span>
                <span className='subtitle'>{dashboard.subtitle}</span>
              </div>
              <div className='filter-section'>
                <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
                  <span className={'filter'}>{dashboard.filteredBy}:</span>
                  <div className={`filter ${selectedClientIndex || selectedClientIndex === 0 ? 'byName' : ''}`}>
                    {selectedClientIndex || selectedClientIndex === 0 ? clientsList[selectedClientIndex]?.name : 'None'}
                    <div onClick={handleClearClients} style={{ display: 'flex', alignItems: 'center' }}>
                      {(selectedClientIndex || selectedClientIndex === 0) && <SVG icon={'semantic/circle-x'} />}
                    </div>
                  </div>
                </div>
              </div>
              <DashboardProjectsComponent
                {...{
                  isCollapsed,
                  selectedClientIndex,
                  handleStartDesign,
                  projectsList,
                  handleProjectClick,
                  houseTypesInfo,
                  unfilteredProjects,
                  clientsList
                }}
              />
              <div className={`clients-wrapper ${isCollapsed ? 'collapsed' : 'expanded'}`}>
                <div className='collapser-div'>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setIsCollapsed(!isCollapsed);
                    }}
                  >
                    <SVG icon={!isCollapsed ? 'navigation/collapse' : 'navigation/expand'} />
                  </div>
                </div>
                {!isCollapsed && (
                  <>
                    <div style={{ width: '100%', marginTop: '66px' }}>
                      <TextInput
                        value={clientSearch}
                        icon={'action/search'}
                        placeholder=''
                        type='search'
                        name='search'
                        id='search'
                        clearFn={handleClearClients}
                        label={dashboard.search}
                        customCSS={{ height: '44px', width: '100%' }}
                        onChangeFn={e => {
                          handleClientSearch(e.target.value);
                        }}
                      />
                    </div>
                    <div className='clients-section'>
                      <span className='clients-title'>{dashboard.clients}</span>
                      <div className='clients-cards'>
                        {clientsList.map((client, index) => (
                          <div
                            key={client.id}
                            className={`client-card ${selectedClientIndex === index ? 'selected' : ''}`}
                            onClick={() => {
                              handleClientSelection(client, index);
                            }}
                          >
                            <span className='client-name'>{client.name}</span>
                            {Object.entries(client).map(([key, value], idx) => {
                              if (key === 'name' || key === 'id') return null;
                              return (
                                <div
                                  key={`${client.id}-${key}-${idx}`}
                                  style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                  <span className='client-info-key'>
                                    {value?.label?.charAt(0)?.toUpperCase() + value?.label?.slice(1)}
                                  </span>
                                  <span className='client-info-value'>{value.value}</span>
                                </div>
                              );
                            })}
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default Dashboard;
