import { useRef, useState, useEffect } from 'react';
// hooks
import useRedux from '@hooks/useRedux';
// components
import Button from '../../ui/Buttons/Button/Button';
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import { Popper } from '@mui/base/Popper';
import MenuItem from '@components/ui/MenuItem/MenuItem';
// utils
import { DIALOG_TYPE } from '@utils/enums';
import { getLayoutDetails } from '@utils/areas';
// styles
import './PanelFooter.scss';
//services
import { updateProject } from '@services/projects';
//hooks
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
//translations
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

// Custom hook to detect click outside of a given element
const useClickOutside = (ref, handler, exceptionRef) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !(exceptionRef?.current && exceptionRef.current.contains(event.target))
      ) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler, exceptionRef]);
};

const PanelFooter = ({
  formState,
  currentStepNr,
  currentStepId,
  setNextFormStep,
  setProjectSubmittedPageOpen,
  showPreferencesPanel
}) => {
  const {
    dispatchSetDialog,
    PRICING,
    dispatchNotification,
    getCurrentProject,
    FORMSTATE,
    dispatchResetFilters,
    dispatchUpdateTracking,
    getTracking,
    getHouseTypes,
    PREFERENCES,
    getHouses
  } = useRedux();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const popperRef = useRef(null); // Reference to the popper content
  const buttonRef = useRef(null); // Reference to the toggle button
  const { watch } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currency = PREFERENCES.get().currency;
  const units = PREFERENCES.get().units;

  const handleClick = event => {
    if (anchorEl) {
      setAnchorEl(null); // Close popper if it's open
    } else {
      setAnchorEl(event.currentTarget); // Open popper if it's closed
    }
  };

  const handleNextButton = async () => {
    if (getTracking().wasSelectedOnImageContainer) {
      dispatchUpdateTracking({
        idToUpdate: 'plotSelectedOnImageContainer',
        updatedObject: { wasSelectedOnImageContainer: false }
      });
    }

    if (currentStepId === 'interiors') PRICING.update({ formState, currency });
    setNextFormStep(currentStepNr++);
    try {
      const house = getHouses()?.filter(house => house?.id === watcher?.layout?.houseLayout);
      const details = getLayoutDetails(house[0]?.modules?.combinations, units);
      const area = details?.area;

      const updateResponse = await updateProject(
        {
          form: watcher,
          plot: watcher?.plot?.plot ?? null,
          size: currentStepNr !== 0 ? area : null,
          layout: getHouseTypes()?.find(el => el.id === watcher?.layout?.houseType)?.id
        },
        getCurrentProject().id
      );
    } catch (error) {
      console.log('Error saving project', error);
      dispatchNotification({
        timer: 'false',
        type: 'banner',
        status: 'error',
        title: t('projects.error_saving_project_title'),
        description: t('projects.error_saving_project_description')
      });
      navigate('/');
    }
  };

  // Use the custom hook to close the popper when clicking outside, except for the toggle button
  useClickOutside(popperRef, () => setAnchorEl(null), buttonRef);

  // Update pricing when currency changes
  useEffect(() => {
    if (currentStepNr >= 2) {
      PRICING.update({ formState, currency });
    }
  }, [PREFERENCES.get().currency]);

  const journeyFooter = (
    <>
      <IconButton ref={buttonRef} icon={'action/grid'} onClickFn={handleClick} />
      <Popper id={id} open={open} anchorEl={anchorEl} placement='top-start' style={{ zIndex: 9999 }}>
        <div className='more-menu' ref={popperRef}>
          {/* consult price only available on step Exteriors and following */}
          {!['plot', 'layout'].includes(currentStepId) && (
            <MenuItem
              text='Consult Price'
              onClickFn={() => {
                PRICING.update({ formState, currency });
                dispatchSetDialog({ open: true, type: 'image', id: DIALOG_TYPE.ConsultPrice });
              }}
            />
          )}
          <MenuItem
            text='Save and Exit'
            onClickFn={() => {
              dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SaveExit });
              handleClick();
            }}
          />
          <MenuItem text='Preferences' onClickFn={showPreferencesPanel} />
        </div>
      </Popper>
      <Button primary text={'Next'} onClickFn={handleNextButton} />
    </>
  );

  const reviewFooter = (
    <>
      <IconButton ref={buttonRef} icon={'action/grid'} onClickFn={handleClick} />
      <Popper id={id} open={open} anchorEl={anchorEl} placement='top-start' style={{ zIndex: 9999 }}>
        <div className='more-menu' ref={popperRef}>
          <MenuItem
            text='Save and Exit'
            onClickFn={() => {
              dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SaveExit });
              handleClick();
            }}
          />
          <MenuItem text='Preferences' onClickFn={showPreferencesPanel} />
        </div>
      </Popper>
      <div className='review-buttons'>
        <Button
          secondary
          text={'Save project'}
          onClickFn={() => {
            dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SaveProject });
          }}
        />
        <Button
          primary
          text={'Confirm interest'}
          onClickFn={() => {
            dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SubmitProject });
          }}
        />
      </div>
    </>
  );

  return <div className='form-footer'>{currentStepId === 'review' ? reviewFooter : journeyFooter}</div>;
};

export default PanelFooter;
