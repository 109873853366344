// hooks
import useRedux from '@hooks/useRedux';
import { useNavigate } from 'react-router-dom';
// components
import Button from '@components/ui/Buttons/Button/Button';
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import PriceCard from '@components/ui/Cards/PriceCard/PriceCard';
// services
import { createRequest } from '@services/client';
import { updateProject } from '@services/projects';
import { sendEmail } from '@services/emails';
import Cookies from 'js-cookie';
// utils
import { ACTIONS, DIALOG_TYPE } from '@utils/enums';
import { getLayoutDetails } from '@utils/areas';
// styles
import './Dialog.scss';
import dialogImg from '@assets/img/dialog.png';
//hooks
import { useFormContext } from 'react-hook-form';
//translations
import { useTranslation } from 'react-i18next';
//env
import { FRONTEND_URL } from '../../../../config';

const Dialog = ({ type, id, projectName, onClose, setCustomFormStep, setProjectSubmittedPageOpen }) => {
  const {
    getModals,
    dispatchSetDialog,
    dispatchResetForm,
    dispatchNotification,
    dispatchProjectInfo,
    getCurrentProject,
    dispatchResetTracking,
    FORMSTATE,
    dispatchResetFilters,
    getHouseTypes,
    getHouses,
    PREFERENCES,
    getTracking
  } = useRedux();
  const navigate = useNavigate();
  const dialog = getModals()?.filter(modal => modal.id === id)[0]?.content[0];
  const sales_manager = Cookies.get('salesManager');
  const sales_manager_token = Cookies.get('sessionId');
  const sales_manager_id = Cookies.get('salesManagerId');
  const { watch } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();
  const { t } = useTranslation();
  const units = PREFERENCES.get().units;

  const getType = type => {
    return type === 'image' ? 'dialog-image' : 'dialog';
  };

  const dialogClass = `${getType(type)}`;

  const handleClick = async action => {
    dispatchSetDialog({ open: false, type: '', id: '', title: '' });
    if (action === ACTIONS.NewDesign) {
      dispatchResetForm({ reset: true, trigger: 'RESET_STEP_PLOT' });
      FORMSTATE.reset();
      dispatchResetFilters();
      setCustomFormStep(0);
      await createRequest(
        {
          clientEmail: getCurrentProject().client,
          salesManagerEmail: sales_manager,
          salesManagerId: sales_manager_id,
          projectDefaultLanguage: PREFERENCES?.get()?.language?.code
        },
        sales_manager_token
      )
        .then(async response => {
          if (response) {
            console.log('Project saved to DB', response);
            dispatchProjectInfo(response.projectInfo);
            navigate(`/journey/${response.projectInfo.id}`);
          }
        })
        .catch(error => {
          console.log('Error saving project do DB', error);
          dispatchNotification({
            status: 'error',
            title: t('projects.error_saving_project_title'),
            description: t('projects.error_saving_project_description')
          });
          navigate('/');
        });
    }
    if (action === ACTIONS.Exit) {
      if (id === DIALOG_TYPE.SaveExit) {
        try {
          const house = getHouses()?.filter(house => house?.id === watcher?.layout?.houseLayout);
          const details = getLayoutDetails(house[0]?.modules?.combinations, units);
          const area = details?.area;
          onClose();

          const updateResponse = await updateProject(
            {
              form: watcher,
              plot: watcher?.plot?.plot ?? null,
              size: getTracking()?.currentStepId !== 'plot' ? area : null,
              layout: getHouseTypes()?.find(el => el.id === watcher?.layout?.houseType)?.id
            },
            getCurrentProject().id
          );
          navigate('/');
          console.log('Project saved to DB', updateResponse);
          dispatchNotification({
            status: 'success',
            title: t('projects.saving_project_success')
          });
        } catch (error) {
          console.log('Error saving project', error);
          navigate('/');
          dispatchNotification({
            status: 'error',
            title: t('projects.error_saving_project_title'),
            description: t('projects.error_saving_project_description')
          });
        }
      } else if (id === DIALOG_TYPE.SaveProject) {
        try {
          const house = getHouses()?.filter(house => house?.id === watcher?.layout?.houseLayout);
          const details = getLayoutDetails(house[0]?.modules?.combinations, units);
          const area = details?.area;
          onClose();
          const emailPayload = {
            to: 'joaotalegria@gmail.com',
            identifier: 'project-submitted',
            projectName: getCurrentProject()?.configurationName ?? getCurrentProject()?.configurationDefaultName,
            bespokeId: getCurrentProject()?.id,
            actionURL: `${FRONTEND_URL}/journey/${getCurrentProject().id}`, 
          };

          sendEmail(emailPayload);

          const updateResponse = await updateProject(
            {
              form: watcher,
              plot: watcher?.plot?.plot ?? null,
              size: getTracking()?.currentStepId !== 'plot' ? area : null,
              layout: getHouseTypes()?.find(el => el.id === watcher?.layout?.houseType)?.id
            },
            getCurrentProject().id
          );
          navigate(`/dashboard?clientId=${getCurrentProject().clientId.id}`);
          console.log('Project saved to DB', updateResponse);
          dispatchNotification({
            status: 'success',
            title: t('projects.saving_project_success')
          });
        } catch (error) {
          console.log('Error saving project', error);
          navigate(`/dashboard?clientId=${getCurrentProject().clientId.id}`);
          dispatchNotification({
            status: 'error',
            title: t('projects.error_saving_project_title'),
            description: t('projects.error_saving_project_description')
          });
        }
      } else {
        PREFERENCES.reset();
        dispatchResetTracking();
        navigate(`/dashboard?clientId=${getCurrentProject().clientId.id}`);
      }
      FORMSTATE.reset();
      dispatchResetFilters();
      dispatchProjectInfo(null);
    }
    if (action === ACTIONS.Back) onClose();
    if (action === ACTIONS.ConfirmInterest) {
      try {
        const house = getHouses()?.filter(house => house?.id === watcher?.layout?.houseLayout);
        const details = getLayoutDetails(house[0]?.modules?.combinations, units);
        const area = details?.area;
        onClose();
        const emailPayload = {
          to: 'joaotalegria@gmail.com',
          identifier: 'project-submitted',
          projectName: getCurrentProject()?.configurationName ?? getCurrentProject()?.configurationDefaultName,
          bespokeId: getCurrentProject()?.id,
          actionURL: `${FRONTEND_URL}/journey/${getCurrentProject().id}`, 
        };

        sendEmail(emailPayload);
        const updateResponse = await updateProject(
          { status: 'submitted', size: getTracking()?.currentStepId !== 'plot' ? area : null },
          getCurrentProject().id
        );
        setProjectSubmittedPageOpen(true);
        console.log('Project saved to DB', updateResponse);
        dispatchNotification({
          status: 'success',
          title: t('projects.saving_project_success')
        });
      } catch (error) {
        console.log('Error saving project', error);
        navigate('/');
        dispatchNotification({
          status: 'error',
          title: t('projects.error_submitting_project_title'),
          description: t('projects.error_submitting_project_description')
        });
      }
    }
    return;
  };

  return (
    <div className={dialogClass.trim()}>
      {type === 'image' && <img src={dialogImg} alt='' />} {/* FIX SOURCE */}
      <div className={`${dialogClass.trim()}-content`}>
        <div className='dialog-header'>
          <div className='dialog-header-text'>
            <div className='dialog-header-text-left'>
              <span className='title'>{dialog?.title?.replace('{{projectName}}', projectName)}</span>
            </div>
            <IconButton
              className='close'
              size={'small'}
              onImageBg={false}
              icon={'semantic/close'}
              onClickFn={onClose}
            />
          </div>
          <span className='subtitle'>{dialog?.description}</span>
        </div>
        {type === 'image' && <PriceCard></PriceCard>}
        <div className='dialog-footer'>
          {dialog?.actions.map(action => {
            const type = action.type.toLowerCase();
            if (type === 'primary') {
              return (
                <Button
                  primary
                  text={action.label}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            } else if (type === 'primary-destructive') {
              return (
                <Button
                  primary
                  destructive
                  text={action.label}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            } else if (type === 'secondary') {
              return (
                <Button
                  secondary
                  text={action.label}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            } else if (type === 'ghost') {
              return (
                <Button
                  ghost
                  text={action.label}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Dialog;
