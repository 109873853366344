import { useEffect, useState, useRef, useCallback } from 'react';
import Cookies from 'js-cookie';
import '../Journey.scss';
// assets
import REVIEW_DATA from '@assets/json/review.json';
// hooks
import useRedux from '@hooks/useRedux';
import useImage from '@hooks/useImage';
import { useForm, FormProvider } from 'react-hook-form';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
// components
import PanelHeader from '@components/side-panel/PanelHeader/PanelHeader';
import PanelFooter from '@components/side-panel/PanelFooter/PanelFooter';
import PanelContent from '@components/side-panel/PanelContent/PanelContent';
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import FiltersSorting from '@components/filters-sorting/FiltersSorting';
import ImageContainer from '@components/image-container';
import ProjectSubmitted from '@components/ProjectSubmitted/ProjectSubmitted';
// utils
import { isObjectsEquals } from '@utils/utils';
import { isEmpty } from '@utils/functions';
import { useParams } from 'react-router-dom';
import { setPrevent } from '@utils/preventions';
//services
import { getProjectById } from '@services/projects';
import Portal from '@components/ui/Overlays/Portal/Portal';
import Preferences from '@components/preferences/Preferences';

const JourneyBuilder = () => {
  // !! JourneyBuilder can't access useRender

  const {
    getForms,
    dispatchUpdateCurrentStepId,
    FORMSTATE,
    getTracking,
    dispatchResetForm,
    dispatchNotification,
    getCurrentProject,
    dispatchProjectInfo,
    dispatchPlots,
    PRICING,
    PREFERENCES
  } = useRedux();

  const { setImageContainerImage } = useImage();
  const projectId = useParams()?.id;
  const sales_manager_id = Cookies.get('salesManagerId');
  const navigate = useNavigate();
  const preferences = PREFERENCES.get();

  const formMethods = useForm({
    defaultValues: {
      plot: { macroplot: '', plot: '' }
    },
    shouldUnregister: false,
    mode: 'onSubmit'
  });

  const { handleSubmit, watch, control, reset, getValues, trigger, setValue } = formMethods;

  // Check if SM can access the project
  // Load project selections when resuming from dashboard
  useEffect(() => {
    dispatchPlots(Cookies.get('sessionId'));
    async function fetchProjectById() {
      if (!getCurrentProject() || isEmpty(getCurrentProject())) {
        if (projectId && preferences.languageOptions) {
          try {
            const project = await getProjectById(projectId, sales_manager_id);
            if (project) {
              if (!isObjectsEquals(project?.form, { plot: { macroplot: '', plot: '' } })) {
                //When data comes from DB, we shouldn't reset the rest of the form
                setPrevent('BdInfo');
              }
              reset(project?.form); // Load project selections
              dispatchProjectInfo(project);
              // set preferences from loaded project
              PREFERENCES.updateLanguage(
                preferences.languageOptions.find(option => option.code === project?.projectLanguage)
              );
              PREFERENCES.updateCurrency(project?.projectCurrency);
              PREFERENCES.updateUnits(project?.projectUnits);
            } else {
              dispatchNotification({
                timer: true,
                type: 'banner',
                status: 'error',
                title: 'Project does not exist or is not assigned to you.'
              });
              navigate('/');
            }
          } catch (error) {
            dispatchNotification({
              timer: true,
              type: 'banner',
              status: 'error',
              title: 'Error while getting project.'
            });
            navigate('/');
          }
        }
      }
    }
    fetchProjectById();
  }, [preferences]);

  const watcher = watch();
  const selectedValues = getValues();
  // useEffect(()=> {
  //   if (getCurrentProject()?.form) {
  //     setValue(getCurrentProject()?.form);
  //   }
  // }, [])

  // form
  const [FORM_STEPS, setFormSteps] = useState(null);
  const [FORM_STEPS_NAMES, setFormStepsNames] = useState(null);
  const [FORM_STEPS_ID, setFormStepsId] = useState(null);

  // currentStep form
  const [currentStepNr, setCurrentStep] = useState(0);
  const [currentStepId, setCurrentStepId] = useState('plot');

  // project submitted page
  const [projectSubmittedPageOpen, setProjectSubmittedPageOpen] = useState(false);

  // filters panel
  const [showFiltersPanel, setShowFiltersPanel] = useState(false);

  // preferences panel
  const [showPreferencesPanel, setShowPreferencesPanel] = useState(false);

  // inline message
  const [inlineMessage, setInlineMessage] = useState({});

  // image container
  const [isImageContainerExpanded, setIsImageContainerExpanded] = useState(false);
  const sidePanelRef = useRef(null);

  useEffect(() => {
    // const getFirstMacroplotImage = (watcher) => {
    //   setImageContainerImage(watcher);
    // };
    // getFirstMacroplotImage(watcher);

    if (preferences?.language !== null) {
      setFormSteps(getForms().journey?.[preferences?.language?.code].steps);
      setFormStepsNames(getForms().journey?.[preferences?.language?.code]?.steps.map(form => form.title));
      setFormStepsId(getForms().journey?.[preferences?.language?.code]?.steps.map(form => form.id));
    }
  }, [getForms().journey, preferences?.language]);

  const setNextFormStep = async () => {
    // Validate all fields are selected before moving to the next page
    const isValid = await trigger();
    if (isValid) {
      setCurrentStep(currentStepNr => currentStepNr + 1);
      setCurrentStepId(FORM_STEPS[currentStepNr + 1].id);
      dispatchUpdateCurrentStepId(FORM_STEPS[currentStepNr + 1].id);
      // dispatchUpdateFormState({ stepId: currentStepId, obj: watcher[currentStepId] });
      FORMSTATE.update({ stepId: currentStepId, obj: selectedValues[currentStepId] });
      FORMSTATE.dispatchUpdateStepFinished(FORM_STEPS_ID[currentStepNr]);
      FORMSTATE.dispatchUpdateStepChanged(false);
      setInlineMessage({});
    } else {
      dispatchNotification({
        timer: false,
        type: 'toast',
        status: 'error',
        title: 'Required fields missing.',
        description: "You haven't selected all the required fields yet. Please make sure you have selected each one."
      });
      setInlineMessage({ type: 'error', message: 'Select an option to proceed' });
    }
  };

  const setPreviousFormStep = () => {
    setCurrentStep(currentStepNr => currentStepNr - 1);
    setCurrentStepId(FORM_STEPS[currentStepNr - 1].id);
  };

  const setCustomFormStep = index => {
    setCurrentStep(index);
    setCurrentStepId(FORM_STEPS[index].id);
    dispatchUpdateCurrentStepId(FORM_STEPS[index].id);
    FORMSTATE.dispatchUpdateStepChanged(false);

    if (index === 0) {
      // if moving back to step 0 -> clean states
      // dispatchUpdateTracking({
      //   idToUpdate: "nrRooms",
      //   updatedObject: null,
      // });
      // setNewNrRooms(undefined);
    }
  };

  useEffect(() => {
    console.log('watcher -> ', watcher);
  }, [watcher, currentStepNr]);

  useEffect(() => {
    const formState = FORMSTATE.get();
    let stepChanged = false;
    Object.keys(formState).forEach(key => {
      if (key === currentStepId && !isObjectsEquals(watcher[key], formState[key])) {
        stepChanged = true;
      }
    });

    if (stepChanged) {
      FORMSTATE.dispatchUpdateStepChanged(true);
    }
  }, [watcher, currentStepId]);

  useEffect(() => {
    if (getTracking().reset.state) {
      if (getTracking().reset.trigger === 'RESET_STEP_PLOT') {
        reset({ plot: { macroplot: '', plot: '' } });
        dispatchResetForm({ reset: false });
      }
      if (getTracking().reset.trigger === 'RESET_ALL_MACROPLOT') {
        console.log('RESET_ALL_MACROPLOT');
        reset(getTracking().reset.after);
        dispatchResetForm({ reset: false });
      }
      if (getTracking().reset.trigger === 'RESET_ALL_PLOT') {
        console.log('RESET_ALL_PLOT');
        reset(getTracking().reset.after);
        dispatchResetForm({ reset: false });
      }
      if (getTracking().reset.trigger === 'RESET_EXTERIORS_INTERIORS') {
        console.log('RESET_EXTERIORS_INTERIORS');
        reset(getTracking().reset.after);
        dispatchResetForm({ reset: false });
      }
    }
  }, [getTracking]);

  const debouncedSetImage = useCallback(
    debounce(newWatcher => {
      console.log('Debounced');
      setImageContainerImage(newWatcher);
    }, 100),
    [setImageContainerImage]
  );

  useEffect(() => {
    debouncedSetImage(watcher);
  }, [watcher, debouncedSetImage]);

  // If pool = none remove others
  useEffect(() => {
    if (getValues()?.exteriors && !isEmpty(getValues()?.exteriors)) {
      const selectedValues = getValues();
      const { poolSize, poolCovering, poolHeated } = selectedValues.exteriors.pool;
      if (poolSize === 'none' && poolCovering != null && poolHeated != null) {
        selectedValues.exteriors.pool.poolCovering = null;
        selectedValues.exteriors.pool.poolHeated = null;
        setValue('exteriors.pool.poolCovering', selectedValues.exteriors.pool.poolCovering);
        setValue('exteriors.pool.poolHeated', selectedValues.exteriors.pool.poolHeated);
      }
    }
  }, [getValues()?.exteriors?.pool.poolSize]);

  // Actions when moving through steps
  useEffect(() => {
    // Scroll to the top of the side-panel
    if (sidePanelRef.current) {
      sidePanelRef.current.scrollTop = 0;
    }
  }, [showFiltersPanel, FORM_STEPS, currentStepId, currentStepNr, setValue]);

  // const ImageContainerRender = () => {
  //   if (currentStepId === 'plot') return <Map svg={svg} />;
  //   if (currentStepId === 'review' && isArray(imagesReview) && !isEmpty(imagesReview))
  //     return <Slideshow className='slideshow' images={imagesReview} autoSlide />;
  //   if (currentStepId !== 'plot' && currentStepId !== 'review' && isArray(currentImage) && !isEmpty(currentImage))
  //     return <Slideshow className='slideshow' images={currentImage} />;
  //   if (currentStepId !== 'plot' && currentStepId !== 'review')
  //     return <Photo image={currentImage} fallbackImagePath={fallbackImagePath} />;
  // };

  return (
    // CHECK TO OPTIMIZE
    <>
      {projectSubmittedPageOpen && (
        <>
          <ProjectSubmitted
            setCustomFormStep={setCustomFormStep}
            setProjectSubmittedPageOpen={setProjectSubmittedPageOpen}
          />
        </>
      )}
      {!projectSubmittedPageOpen && FORM_STEPS && (
        <div className={`container${isImageContainerExpanded ? ' expanded' : ''}`}>
          {/* COLLAPSABLE ICON */}
          {!showFiltersPanel && (
            <div className={`top${isImageContainerExpanded ? ' expanded' : ''}`}>
              {isImageContainerExpanded && (
                <IconButton
                  id='expand'
                  size={'small'}
                  onImageBg={false}
                  icon={'navigation/expand'}
                  onClickFn={() => setIsImageContainerExpanded(!isImageContainerExpanded)}
                />
              )}
              {!isImageContainerExpanded && (
                <IconButton
                  id='collapse'
                  size={'small'}
                  onImageBg={false}
                  icon={'navigation/collapse'}
                  onClickFn={() => setIsImageContainerExpanded(!isImageContainerExpanded)}
                />
              )}
            </div>
          )}

          <FormProvider {...formMethods}>
            {/* IMAGE CONTAINER */}
            <div className='image-container'>
              {/* {Array.isArray(getImages().current) && getImages().current.length >= 1 ? (
                <Slideshow images={getImages().current} />
              ) : (
                <ImageContainer />
              )} */}
              {/* {ImageContainerRender()} */}
              <ImageContainer />
            </div>

            {/* SIDE PAINEL */}
            {showFiltersPanel ? (
              <Portal noBlur>
                <div className='side-panel' ref={sidePanelRef}>
                  <FiltersSorting
                    onView={() => {
                      setShowFiltersPanel(!showFiltersPanel);
                      // setIsImageContainerExpanded(!isImageContainerExpanded);
                    }}
                  />
                </div>
              </Portal>
            ) : showPreferencesPanel ? (
              <Portal noBlur>
                <div className='side-panel' ref={sidePanelRef}>
                  <Preferences
                    formState={selectedValues}
                    onClose={() => {
                      setShowPreferencesPanel(!showPreferencesPanel);
                    }}
                  />
                </div>
              </Portal>
            ) : (
              <div className='side-panel' ref={sidePanelRef}>
                <PanelHeader
                  stepsNames={FORM_STEPS_NAMES}
                  stepsIds={FORM_STEPS_ID}
                  currentStepNr={currentStepNr}
                  onStepClick={newCurrentStep => setCustomFormStep(newCurrentStep)}
                />
                <PanelContent
                  formState={selectedValues}
                  formMethods={formMethods}
                  steps={FORM_STEPS}
                  currentStepNr={currentStepNr}
                  currentStepId={currentStepId}
                  REVIEW_DATA={REVIEW_DATA}
                  setCustomFormStep={setCustomFormStep}
                  setShowFiltersPanel={() => setShowFiltersPanel(!showFiltersPanel)}
                  inlineMessage={inlineMessage}
                  setProjectSubmittedPageOpen={() => {
                    setProjectSubmittedPageOpen(!projectSubmittedPageOpen);
                  }}
                />
                <PanelFooter
                  formState={selectedValues}
                  currentStepNr={currentStepNr}
                  currentStepId={currentStepId}
                  setNextFormStep={setNextFormStep}
                  setPreviousFormStep={setPreviousFormStep}
                  setCustomFormStep={setCustomFormStep}
                  setProjectSubmittedPageOpen={() => {
                    setProjectSubmittedPageOpen(!projectSubmittedPageOpen);
                  }}
                  showPreferencesPanel={() => setShowPreferencesPanel(!showPreferencesPanel)}
                />
              </div>
            )}
          </FormProvider>
        </div>
      )}
    </>

    /**
     * Dynamic Renders the following components
     * 1. Plot
     * 2. HouseLayout
     * 3. Exteriors
     * 4. Interiors
     * 5. Review
     */
  );
};

export default JourneyBuilder;
