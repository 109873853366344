import { formsActions } from './forms-slice';
import { getForms } from '../../services/forms';

export const setForm = async ({ dispatch, additionalData }) => {
  try {
    const response = await getForms();
    Object.keys(response).forEach(key => {
      if (key === 'journey') {
        const { macroplots, plots } = additionalData;
        Object.keys(response[key]).forEach(form => {
          if (macroplots && plots) {
            response[key][form].steps[0].questions[0].answers = [{ items: macroplots }];
            response[key][form].steps[0].questions[1].answers = [{ items: plots }];
          }
        });
      }
    });
    return dispatch(formsActions.setForm(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
