import { SVG } from '@assets/svg';
import './Dropdown.scss';
import { useState, useEffect, useRef } from 'react';
import MenuItem from '../MenuItem/MenuItem';
import { Popper } from '@mui/base/Popper';

const useClickOutside = (ref, handler, exceptionRef) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !(exceptionRef?.current && exceptionRef.current.contains(event.target))
      ) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler, exceptionRef]);
};

const Dropdown = ({ label, placeholder, options, optionsToDisplay, onChange, value, optional, errorMessage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const [typedChars, setTypedChars] = useState('');
  const popperRef = useRef(null); // Reference to the popper content
  const buttonRef = useRef(null); // Reference to the toggle button

  const handleClick = event => {
    if (anchorEl) {
      setAnchorEl(null); // Close popper if it's open
    } else {
      setAnchorEl(event.currentTarget); // Open popper if it's closed
    }
  };

  // Use the custom hook to close the popper when clicking outside, except for the toggle button
  useClickOutside(popperRef, () => setAnchorEl(null), buttonRef);

  const [selection, setSelection] = useState(null);

  useEffect(() => {
    // set value if value was already setted
    if (value) {
      setSelection(value);
    } else {
      setSelection(null);
    }
  }, [value]);

  useEffect(() => {
    const handleKeyDown = event => {
      const char = event.key?.toLowerCase();
      if (char.length === 1 && char.match(/[a-z]/i)) {
        setTypedChars(prev => prev + char);
      } else if (char === 'Backspace') {
        setTypedChars('');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (typedChars && open) {
      const match = options.find(option => option.toLowerCase().startsWith(typedChars));
      if (match) {
        const matchIndex = options.indexOf(match);
        const dropdownContent = document.querySelector('.dropdown-menu');
        const matchElement = dropdownContent.children[matchIndex];
        if (matchElement) {
          matchElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }, [typedChars, options]);

  useEffect(() => {
    if (typedChars) {
      setTypedChars('');
    }
  }, [open]);

  return (
    <>
      <div className='dropdown-content'>
        <div className='dropdown-label-container'>
          <label className='dropdown-label'>{label}</label>
          {optional && <span className='dropdown-optional'>Optional</span>}
        </div>
        <div onClick={handleClick}>
          {!open && (
            <div className={`dropdown-box-close${errorMessage ? ' error' : ''}`}>
              {selection ? (
                <span className='item-selected'>{selection}</span>
              ) : (
                <span className='item-placeholder'>{placeholder}</span>
              )}
              <SVG icon={'navigation/chevron-up'} />
            </div>
          )}
          {open && (
            <div className={`dropdown-box-open${errorMessage ? ' error' : ''}`}>
              {selection ? (
                <span className='item-selected'>{selection}</span>
              ) : (
                <span className='item-placeholder'>{placeholder}</span>
              )}
              <SVG icon={'navigation/chevron-up'} />
            </div>
          )}
        </div>
        <span className='errorMessage'>{errorMessage}</span>
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='bottom'
        style={{ zIndex: 9999, width: anchorEl?.clientWidth }}
      >
        <div className='dropdown-menu' ref={popperRef}>
          {options.map((item, index) => {
            return (
              <MenuItem
                text={optionsToDisplay ? optionsToDisplay[index] : item}
                icon={'semantic/check'}
                selected={optionsToDisplay ? selection === optionsToDisplay[index] : selection === item}
                onClickFn={() => {
                  setSelection(optionsToDisplay ? optionsToDisplay[index] : item);
                  onChange(item);
                  handleClick();
                }}
              />
            );
          })}
        </div>
      </Popper>
    </>
  );
};

export default Dropdown;
