import React, { useState } from 'react';
//components
import Header from '@components/ui/Header/Header';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
import Button from '@components/ui/Buttons/Button/Button';
//styles
import './client.scss';
//hooks
import { useNavigate } from 'react-router-dom';
import image from './image.png';
import useRedux from '@hooks/useRedux';
//services
import { getProjectsBySalesManagerAndProject } from '@services/projects';
import { getClientDetails, createRequest } from '@services/client';
import Cookies from 'js-cookie';
//translations
import { useTranslation } from 'react-i18next';
//utils
import { isEmpty, isValidEmail } from '@utils/functions';

const ClientIdPage = ({}) => {
  const navigate = useNavigate();
  const { dispatchProjectInfo, dispatchNotification, dispatchResetNotification, dispatchClient, PREFERENCES } =
    useRedux();
  const [clientEmail, setClientEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();
  const sales_manager = Cookies.get('salesManager');
  const sales_manager_token = Cookies.get('sessionId');
  const sales_manager_id = Cookies.get('salesManagerId');

  const validateEmail = async () => {
    if (!clientEmail) {
      setErrorMessage(t('client_id.empty_email'));
      return;
    }

    if (!isValidEmail(clientEmail)) {
      setErrorMessage(t('client_id.invalid_email'));
      return;
    }

    try {
      setErrorMessage(null);
      const clientSFInfo = await getClientDetails(clientEmail, sales_manager_token);
      const clientRecords = clientSFInfo?.data?.records;

      if (clientRecords && !isEmpty(clientRecords)) {
        const clientRequests = clientRecords[0]?.Requests__r;

        const hasRequestsWithSalesmananager = clientRequests?.records?.some(
          obj => obj?.Owner && obj?.Owner?.Id === sales_manager_id
        );

        if (!hasRequestsWithSalesmananager && clientRecords[0]?.Requests__r?.totalSize > 0) {
          dispatchNotification({
            timer: false,
            status: 'error',
            title: t('client_id.assign_another_sales_manager')
          });
        } else {
          const projectsOfSM = (await getProjectsBySalesManagerAndProject(sales_manager_id, 'TCT')) || [];

          const requestOnPlatform = projectsOfSM?.find(item => item?.clientId?.email === clientEmail);

          if (!requestOnPlatform) {
            await createRequest(
              {
                clientEmail,
                salesManagerEmail: sales_manager,
                salesManagerId: sales_manager_id,
                clientDetailsFromSF: clientSFInfo.data,
                projectDefaultLanguage: PREFERENCES?.get()?.language?.code
              },
              sales_manager_token
            )
              .then(async response => {
                if (response) {
                  dispatchProjectInfo(response.projectInfo);
                  navigate(`/journey/${response.projectInfo.id}`);
                  dispatchClient({ email: clientEmail });
                }
              })
              .catch(error => {
                console.log('Error saving project do DB', error);
                setErrorMessage(t('client_id.errorCreatingRequest'));
              });
          } else {
            navigate(`/dashboard?clientId=${requestOnPlatform.clientId?.id}`);
            dispatchClient({ email: clientEmail });
          }
        }
      } else {
        console.log('Client does not exist');
        navigate('/client/register', { state: clientEmail });
      }
    } catch (error) {
      console.error('Error getting client details', error);
      setErrorMessage(t('client_id.errorGettingDetails'));
    }
  };

  return (
    <div className='client-container'>
      <Header shouldDisplay />

      <div className='client-wrapper'>
        <div className='background-image-container'>
          <div className='overlay'></div>
          <img src={image} className='background-image' />
        </div>

        <div className='content-section'>
          <span className='main-title'>{t('client_id.main_title')}</span>
          <TextInput
            icon={'action/email'}
            placeholder=''
            type='email'
            name='email'
            id='email'
            label={t('client_id.email')}
            customCSS={{ height: '44px' }}
            onChangeFn={e => {
              setClientEmail(e.target.value);
              setErrorMessage(null);
            }}
            errorMessage={errorMessage}
            onEnterPress={() => {
              validateEmail();
              dispatchResetNotification();
            }}
          />
          <div className='button-container'>
            <Button secondary text={t('client_id.back_button')} onClickFn={() => navigate('/')} />
            <Button
              primary
              text={t('client_id.button')}
              onClickFn={() => {
                validateEmail();
                dispatchResetNotification();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientIdPage;
