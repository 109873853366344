import { createSlice } from '@reduxjs/toolkit';
import { updateStepsFinished } from './tracking-actions';

const INITIAL_STATE = {
  currentStepId: 'plot',
  dialog: { open: false, type: '', id: '' },
  wasSelectedOnImageContainer: false,
  reset: { state: false }
};

const trackingSlice = createSlice({
  name: 'tracking',
  initialState: INITIAL_STATE,
  reducers: {
    updateTracking: (state, action) => {
      const { idToUpdate, updatedObject } = action.payload;
      return { ...state, [idToUpdate]: updatedObject };
    },
    updateNrRooms: (state, action) => {
      const { idToUpdate, updatedObject } = action.payload;
      return { ...state, [idToUpdate]: updatedObject };
    },
    updateHouseType: (state, action) => {
      const { idToUpdate, updatedObject } = action.payload;
      return { ...state, [idToUpdate]: updatedObject };
    },
    updateHouseLayout: (state, action) => {
      const { idToUpdate, updatedObject } = action.payload;
      return { ...state, [idToUpdate]: updatedObject };
    },
    updateHouseName: (state, action) => {
      const { idToUpdate, updatedObject } = action.payload;
      return { ...state, [idToUpdate]: updatedObject };
    },
    setRecommendation: (state, action) => {
      const { recommendation } = action.payload;
      return { ...state, recommendation };
    },
    setPlotHover: (state, action) => {
      const { plotId } = action.payload;
      return { ...state, plotHover: plotId };
    },
    setMacroplotHover: (state, action) => {
      const { macroplotId } = action.payload;
      return { ...state, macroplotHover: macroplotId };
    },
    setResetForm: (state, action) => {
      const { reset, trigger, after } = action.payload;
      if (!reset) return { ...state, reset: { ...INITIAL_STATE.reset } };
      return { ...state, reset: { state: true, trigger, after } };
    },
    updateCurrentStepId: (state, action) => {
      const { nextStepId } = action.payload;
      state.currentStepId = nextStepId;
    },
    updateCurrentQuestionId: (state, action) => {
      const { currentQuestionId } = action.payload;
      state.currentQuestionId = currentQuestionId;
    },
    updateInteriorsAreas: (state, action) => {
      const { interiorsAreas } = action.payload;
      state.interiorsAreas = interiorsAreas;
    },
    updateWasSelectedOnImageContainer: (state, action) => {
      const { updatedObject } = action.payload;
      state.wasSelectedOnImageContainer = updatedObject.wasSelectedOnImageContainer;
    },
    setDialog: (state, action) => {
      const { open, type, id } = action.payload;
      if (!open) return { ...state, dialog: { ...INITIAL_STATE.dialog } };
      return { ...state, dialog: { open: open, type: type, id: id } };
    },
    reset: (state, action) => {
      return INITIAL_STATE;
    }
  }
});

const trackingActions = trackingSlice.actions;
export { trackingSlice, trackingActions };
