import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// components
import Checkbox from '@components/ui/Checkbox/Checkbox';
// styles
import './ProfileCell.scss';

const ProfileCell = ({ profile, onSelect, onDeselect, isProfileSelected }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(isProfileSelected);
  }, [isProfileSelected]);

  return (
    <div className={selected ? 'profile-cell selected' : 'profile-cell'}>
      <div className='profile-cell-header'>
        <img src={profile.image} alt='' />
        <div className='profile-cell-content'>
          <div className='profile-cell-text'>
            <span className='profile-cell-text-name'>{profile.name}</span>
            <span className='profile-cell-text-jobTitle'>{profile?.service}</span>
          </div>
          <Link to={`/partners/${profile.id}`} target='_blank' rel='noopener noreferrer' className='link'>
            Know more
          </Link>
        </div>
      </div>
      <div className='profile-cell-right'>
        <Checkbox
          onSelect={() => {
            setSelected(!selected);
            onSelect();
          }}
          onDeselect={() => {
            setSelected(!selected);
            onDeselect();
          }}
          value={selected}
        ></Checkbox>
      </div>
    </div>
  );
};

export default ProfileCell;
