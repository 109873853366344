import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useRedux from '@hooks/useRedux';
import Cookies from 'js-cookie';

//styles
import './enterpriseSelection.scss';
//components
import Header from '@components/ui/Header/Header';

import { createRequest, getClientDetails } from '@services/client';
import { getEnterpriseImages } from '@services/images';

const EnterpriseSelection = () => {
  const { dispatchResetTracking, dispatchProjectInfo, dispatchClient, PREFERENCES, getClient } = useRedux();
  const navigate = useNavigate();
  const sales_manager = Cookies.get('salesManager');
  const sales_manager_id = Cookies.get('salesManagerId');
  const sales_manager_token = Cookies.get('sessionId');
  const [images, setImages] = useState({
    torre: { background: null, logo: null },
    dunas: { background: null, logo: null }
  });

  useEffect(() => {
    dispatchResetTracking();
    PREFERENCES.reset();
  }, [dispatchResetTracking, PREFERENCES]);

  const fetchImages = async () => {
    try {
      const data = await getEnterpriseImages();
      if (data.torreImages && data.dunasImages) {
        setImages({
          torre: {
            background: data.torreImages.find(img => img.id === 'background') || null,
            logo: data.torreImages.find(img => img.id === 'logo') || null
          },
          dunas: {
            background: data.dunasImages.find(img => img.id === 'background') || null,
            logo: data.dunasImages.find(img => img.id === 'logo') || null
          }
        });
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const goToClientPage = async () => {
    const clientSFInfo = await getClientDetails(getClient().email, sales_manager_token);

    await createRequest(
      {
        clientEmail: getClient().email,
        salesManagerEmail: sales_manager,
        salesManagerId: sales_manager_id,
        clientDetailsFromSF: clientSFInfo.data,
        projectDefaultLanguage: PREFERENCES?.get()?.language?.code
      },
      sales_manager_token
    )
      .then(async response => {
        if (response) {
          dispatchProjectInfo(response.projectInfo);
          navigate(`/journey/${response.projectInfo.id}`);
        }
      })
      .catch(error => {
        console.log('Error saving project to DB', error);
      });
  };

  return (
    <div className='homepage-container'>
      <Header shouldDisplay appearDashboard />
      <div className='split-screen'>
        <div
          className='left-section'
          onClick={() => {
            goToClientPage();
            dispatchClient({ email: getClient()?.email });
          }}
        >
          {images.torre.background && (
            <img src={images.torre.background.url} alt='Torre Background' className='split-image' />
          )}
          <div className='overlay'>
            {images.torre.logo && <img src={images.torre.logo.url} alt='Torre Logo' style={{ width: '400px' }} />}
            <div className='overlay-text'>
              <p>
                Lorem ipsum dolor sit amet. Ut asperiores nesciunt quo voluptatem soluta ut aperiam quod At quas rerum
                aut autem galisum qui provident omnis. Et expedita voluptate aut dolores placeat ad commodi iusto.
              </p>
            </div>
          </div>
        </div>

        <div
          className='right-section'
          onClick={() => {
            goToClientPage();
            dispatchClient({ email: getClient()?.email });
          }}
        >
          {images.dunas.background && (
            <img src={images.dunas.background.url} alt='Dunas Background' className='split-image' />
          )}
          <div className='overlay'>
            {images.dunas.logo && <img src={images.dunas.logo.url} alt='Dunas Logo' style={{ width: '400px' }} />}
            <div className='overlay-text'>
              <p>
                Lorem ipsum dolor sit amet. Ut asperiores nesciunt quo voluptatem soluta ut aperiam quod At quas rerum
                aut autem galisum qui provident omnis. Et expedita voluptate aut dolores placeat ad commodi iusto.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseSelection;
