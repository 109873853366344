import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';

export const getHouseTypes = async (includeImage, imageSize) => {
  const HEADERS = {
    accept: '*/*',
  };
  const baseUrl = getFullEndpoint({ microservice: 'houseTypes' });
  const queryParams = [];
  
  if (includeImage) {
    queryParams.push('includeImage=true');
  }
  if (imageSize && includeImage) {
    queryParams.push(`imageSize=${imageSize}`);
  }

  const url = queryParams.length ? `${baseUrl}?${queryParams.join('&')}` : baseUrl;

  const requestObj = {
    method: 'get',
    url,
    headers: HEADERS,
  };
  try {
    const response = await axios.request(requestObj);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
