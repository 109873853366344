import { getHomepageContent } from '@services/singlePages/homepage';
import { homepageAction } from './homepage-slice';
import { preferencesActions } from '../preferences/preferences-slice';

export const setHomepageContent = async dispatch => {
  try {
    const response = await getHomepageContent();
    dispatch(homepageAction.setHomepageContent([{ en: response.en, pt: response.pt }]));
    dispatch(preferencesActions.setLanguageOptions({ languageOptions: response.locales }));
  } catch (error) {
    console.log('ERROR', error);
  } finally {
  }
};
