import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';

export const sendEmail = async (data, token) => {
    const HEADERS = {
      accept: '*/*',
      Authorization: `Bearer ${token}`
    };
  
    const requestObj = {
      method: 'post',
      url: `${getFullEndpoint({ microservice: 'email'})}`,
      headers: HEADERS,
      data
    };
  
    const response = await axios.request(requestObj);
    return response.data;
  };
  