import { useState, useEffect, useRef } from 'react';
// components
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import Information from '@components/ui/Information';
import Dropdown from '@components/ui/Dropdown/Dropdown';
import Button from '@components/ui/Buttons/Button/Button';
import Checkbox from '@components/ui/Checkbox/Checkbox';
import RangeSlider from '@components/ui/Slider/RangeSlider';
import RadioButton from '@components/ui/RadioButton/RadioButton';
import Portal from '@components/ui/Overlays/Portal/Portal';
import BasicModal from '@components/ui/Overlays/Modals/BasicModal/BasicModal';
// hooks
import useRedux from '@hooks/useRedux';
import useFilters from '@hooks/useFilters';
import useModals from '@hooks/useModals';
// styling
import './FiltersSorting.scss';
// utils
import { isEmpty } from '@utils/functions';
import {
  getNrRoomsFilteredByArea,
  getHouseTypesFilteredByArea,
  getNrRoomsFilteredByHouseType,
  getHouseTypesFilteredByNrRooms,
  getAreasFilteredByNrRoomsAndHouseType
} from '@utils/filters';
import { getArea } from '@utils/areas';
import { isObjectsEquals } from '@utils/utils';

const FiltersSorting = ({ onView }) => {
  const plotAreaOptions = ['Min', 2000, 4000, 6000, 8000, 10000, 'Max'];
  const sortLabels = ['Name', 'Size (Ascending Order)', 'Size (Descending Order)'];
  const { getHouseTypes, getForms, dispatchFilters, getFilters, getHouses, getModals, PREFERENCES } = useRedux();
  const { getPlotsFiltered, getPlotsByPlotArea } = useFilters();
  const lang = PREFERENCES.get().language?.code;
  const houseTypes = getHouseTypes();
  const nrRooms = getForms().journey[lang].steps[1].questions[0].answers[0].items;
  const plots = getForms().journey[lang].steps[0].questions[1].answers[0].items;
  const houses = getHouses();
  let plotsFiltered = plots;
  let plotsByArea = plots;
  let nrRoomsFiltered = nrRooms.filter(room => plots.some(plot => plot.houses.some(house => house.includes(room.id))));
  let houseTypesFiltered = houseTypes.filter(houseType =>
    plots.some(plot => plot.houses.some(house => house.includes(houseType.id)))
  );
  let plotMinAreaOptionsFiltered = plotAreaOptions;
  let plotMaxAreaOptionsFiltered = plotAreaOptions;
  const units = PREFERENCES.get().units;

  const [filterState, setFilterState] = useState(
    !isEmpty(getFilters()) ? getFilters() : { sortBy: 'Name', plotMinArea: 'Min', plotMaxArea: 'Max' }
  );

  const [isModalOpen, setModalOpen] = useModals();

  // open and close modal
  const toggleModal = () => setModalOpen(!isModalOpen);

  // filter bedrooms options by area && filter houseType options by area
  if (filterState?.plotMinArea !== 'Min' || filterState?.plotMaxArea !== 'Max') {
    plotsByArea = getPlotsByPlotArea(filterState, plots);
    nrRoomsFiltered = getNrRoomsFilteredByArea(plotsByArea, nrRoomsFiltered);
    houseTypesFiltered = getHouseTypesFilteredByArea(plotsByArea, houseTypesFiltered);
  }

  // filter bedrooms options by houseType
  const housesFilteredByHouseType = houses.filter(house =>
    filterState?.houseType?.some(houseType => house.houseType === houseType)
  );
  if (filterState?.houseType?.length > 0)
    nrRoomsFiltered = getNrRoomsFilteredByHouseType(housesFilteredByHouseType, nrRoomsFiltered, plotsByArea);

  // filter houseType options by bedrooms
  const housesFilteredByTypology = houses.filter(house =>
    filterState?.bedrooms?.some(bedroom => house.typology === bedroom)
  );
  if (filterState?.bedrooms?.length > 0)
    houseTypesFiltered = getHouseTypesFilteredByNrRooms(housesFilteredByTypology, houseTypesFiltered, plotsByArea);

  // filter area options by bedrooms && filter area options by houseType
  plotsFiltered = getAreasFilteredByNrRoomsAndHouseType(
    filterState,
    plotsFiltered,
    housesFilteredByTypology,
    housesFilteredByHouseType
  );
  plotMinAreaOptionsFiltered = plotMinAreaOptionsFiltered.filter(area =>
    plotsFiltered.some(plot => area <= plot.area || area === 'Min')
  );
  plotMaxAreaOptionsFiltered = plotMaxAreaOptionsFiltered.filter(area =>
    plotsFiltered.some(plot => area >= plot.area || area === 'Max')
  );

  const numberLabel = () => {
    const plotCounter = getPlotsFiltered(filterState, plots).length;
    if (plotCounter === 0) {
      return '';
    } else {
      return ` ${plotCounter}`;
    }
  };

  useEffect(() => {
    if (!isObjectsEquals(filterState, getFilters())) dispatchFilters({ filters: filterState });
  }, [filterState]);

  useEffect(() => {
    if (!isObjectsEquals(filterState, getFilters()) && !isEmpty(getFilters())) setFilterState(getFilters());
  }, [getFilters()]);

  return (
    <>
      <div className='filter-header'>
        <IconButton
          size={'small'}
          onImageBg={false}
          icon={'semantic/close'}
          onClickFn={() => {
            dispatchFilters({ filters: filterState });
            onView();
          }}
        />
      </div>
      <div className='filter-content'>
        <div className='filter-title'>
          <span className='headline'>Filters</span>
          <span className='description'>
            To get the most personalised experience, you may sort and filter your options here.
          </span>
        </div>
        <div className='question-title'>
          <span>Plot area</span>
          <div className='dropdown'>
            <Dropdown
              label='Minimum Size'
              options={plotMinAreaOptionsFiltered}
              optionsToDisplay={plotMinAreaOptionsFiltered.map(option => getArea(units, option))}
              onChange={item => {
                if (filterState?.plotMaxArea && item >= filterState.plotMaxArea) {
                  const { plotMaxArea, ...newFilterState } = filterState;
                  setFilterState({
                    ...newFilterState,
                    plotMinArea: item,
                    plotMaxArea: 'Max'
                  });
                } else {
                  setFilterState({ ...filterState, plotMinArea: item });
                }
              }}
              value={filterState?.plotMinArea ? getArea(units, filterState.plotMinArea) : 'Min'}
            ></Dropdown>
            <Dropdown
              label='Maximum Size'
              options={plotMaxAreaOptionsFiltered}
              optionsToDisplay={plotMaxAreaOptionsFiltered.map(option => getArea(units, option))}
              onChange={item => {
                if (filterState?.plotMinArea && item <= filterState.plotMinArea) {
                  const { plotMinArea, ...newFilterState } = filterState;
                  setFilterState({
                    ...newFilterState,
                    plotMinArea: 'Min',
                    plotMaxArea: item
                  });
                } else {
                  setFilterState({ ...filterState, plotMaxArea: item });
                }
              }}
              value={filterState?.plotMaxArea ? getArea(units, filterState.plotMaxArea) : 'Max'}
            ></Dropdown>
          </div>
        </div>

        <div className='question-title'>
          <span>Bedrooms</span>
          {nrRooms.map(room => (
            <Checkbox
              label={room.label}
              disabled={nrRoomsFiltered !== undefined && !nrRoomsFiltered?.includes(room)}
              onSelect={() =>
                setFilterState(prevState => ({ ...prevState, bedrooms: [...(prevState?.bedrooms || []), room.id] }))
              }
              onDeselect={() => {
                filterState.bedrooms.filter(bedroom => bedroom !== room.id).length > 0
                  ? setFilterState(prevState => {
                      return {
                        ...prevState,
                        bedrooms: prevState.bedrooms.filter(bedroom => bedroom !== room.id)
                      };
                    })
                  : (() => {
                      const { bedrooms, ...newState } = filterState;
                      setFilterState(newState);
                    })();
              }}
              value={filterState?.bedrooms?.includes(room.id) || false}
            />
          ))}
        </div>
        <div className='question-information'>
          <Information.Root onClickFn={toggleModal}>
            <Information.Title>Know more about our House Types</Information.Title>
            <Information.Description>
              We recommend layouts based on the selected plot. Find out how a finalized house could look like.
            </Information.Description>
          </Information.Root>
          <div className='house-type'>
            <span>House type</span>
            {houseTypes.map(house => {
              return (
                <Checkbox
                  label={house.label}
                  disabled={houseTypesFiltered !== undefined && !houseTypesFiltered.includes(house)}
                  onSelect={() =>
                    setFilterState(prevState => ({
                      ...prevState,
                      houseType: [...(prevState?.houseType || []), house.id]
                    }))
                  }
                  onDeselect={() => {
                    filterState.houseType.filter(type => type !== house.id).length > 0
                      ? setFilterState(prevState => {
                          return {
                            ...prevState,
                            houseType: prevState.houseType.filter(type => type !== house.id)
                          };
                        })
                      : (() => {
                          const { houseType, ...newState } = filterState;
                          setFilterState(newState);
                        })();
                  }}
                  value={filterState?.houseType?.includes(house.id) || false}
                />
              );
            })}
          </div>
        </div>
        {isModalOpen && (
          <Portal>
            <BasicModal
              currentStepNr={1}
              modals={getModals().filter(modal => modal.id === 'house-types')[0].content}
              onClose={toggleModal}
            ></BasicModal>
          </Portal>
        )}
        <div className='question-title'>
          <span>Price Range</span>
          <RangeSlider
            min={3000000}
            max={12500000}
            step={500000}
            onSelect={([min, max]) => setFilterState({ ...filterState, priceMin: min, priceMax: max })}
            value={filterState?.priceMin ? [filterState.priceMin, filterState.priceMax] : [3000000, 12500000]}
          />
        </div>
      </div>
      <div className='sorting-content'>
        <div className='sorting-title'>
          <span className='headline'>Sorting</span>
        </div>
        <div className='question-title'>
          <span>Sort by</span>
          {sortLabels.map(sort => {
            return (
              <RadioButton
                name='sorting'
                id={sort}
                label={sort}
                onSelect={() => setFilterState({ ...filterState, sortBy: sort })}
                value={filterState?.sortBy == sort ? filterState?.sortBy : false}
              />
            );
          })}
        </div>
      </div>
      <div className='filter-footer'>
        <Button
          secondary
          text={'Clear all'}
          onClickFn={() => {
            setFilterState({ sortBy: 'Name', plotMinArea: 'Min', plotMaxArea: 'Max' });
            dispatchFilters({ filters: {} });
          }}
        />
        <Button
          primary
          text={'View'}
          number={numberLabel()}
          onClickFn={() => {
            dispatchFilters({ filters: filterState });
            onView();
          }}
        />
      </div>
    </>
  );
};

export default FiltersSorting;
