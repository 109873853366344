import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// hooks
import useRedux from '@hooks/useRedux';
// utils
import { isEmpty } from '@utils/functions';
import { Navigate } from 'react-router-dom';
// pages
import Homepage from '@pages/Homepage/Homepage';
import EnterpriseSelection from '@pages/EnterpriseSelection/EnterPriseSelection';
import ProtectedRoute from '@components/auth/ProtectedRoute';
import SalesMangerLogin from '@pages/SalesManager/login/login';
import ClientIdPage from '@pages/Client/ClientIdPage';
import InfoPageComponent from '@pages/InfoPages/InfoPageComponent';
import VerifyCodePage from '@pages/OTP/VerifyCode';
import ClientRegistration from '@pages/ClientRegistration/ClientRegistration';
import ClientOTPRequest from '@pages/OTP/ClientOTPRequest';
import ServicesPartnersPage from '@pages/ServicesPartners/ServicesPartnersPage';
import Admin from '@pages/admin/Admin';
// components
import JourneyBuilder from '@components/forms/JourneyBuilder';
import Notification from '@components/ui/Notification/Notification';
import Dashboard from '@pages/Dashboard/Dashboard';
//services
import { GetInfoPagesContent } from '@services/singlePages/infoPages';

function App() {
  // if (typeof process === 'undefined') {
  //   global.process = {
  //     nextTick: callback => setTimeout(callback, 0),
  //   };
  // }
  const {
    dispatchForms,
    dispatchMacroplots,
    dispatchHouses,
    dispatchModules,
    dispatchHouseTypes,
    dispatchPacks,
    dispatchModals,
    dispatchPartners,
    dispatchProjectSubmittedContent,
    getForms,
    getMacroplots,
    getPlots,
    getNotification,
    dispatchSVG,
    getClient,
    HOMEPAGE,
    DASHBOARD,
    PREFERENCES,
    getIsAuthenticated
  } = useRedux();

  const isAuthenticated = getIsAuthenticated();

  const [infoPagesContent, setInfoPagesContent] = useState(null);

  const forms = getForms();
  const macroplots = getMacroplots();
  const plots = getPlots();
  const notification = getNotification();
  const preferences = PREFERENCES.get();
  const homepageContent = HOMEPAGE.get();

  useEffect(() => {
    HOMEPAGE.set();
  }, []);

  useEffect(() => {
    if (!isEmpty(homepageContent) && preferences.languageOptions) {
      const lang = localStorage.getItem('lang');
      if (lang) {
        const defaultLang = JSON.parse(lang);
        PREFERENCES.updateLanguage(defaultLang);
      } else {
        const defaultLang = preferences.languageOptions.find(option => option.isDefault);
        PREFERENCES.updateLanguage(preferences.languageOptions.find(option => option.isDefault));
        localStorage.setItem('lang', JSON.stringify(defaultLang));
      }
      dispatchMacroplots();
      // dispatchPlots();
      dispatchHouses();
      dispatchModules();
      dispatchHouseTypes();
      dispatchPacks();
      dispatchModals();
      dispatchPartners();
      dispatchProjectSubmittedContent();
      dispatchSVG();
      DASHBOARD.set();
    }
  }, [preferences.languageOptions]);

  useEffect(() => {
    if (!isEmpty(macroplots) && !isEmpty(plots) && isEmpty(forms)) {
      dispatchForms({ additionalData: { macroplots, plots } });
    }
  }, [macroplots, plots]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const infoPages = await GetInfoPagesContent();
        if (!isEmpty(infoPages)) {
          setInfoPagesContent(infoPages);
        }
      } catch (error) {
        console.error('Error fetching info pages:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Homepage homepageContent={homepageContent} />} />
          <Route path='/admin' element={<Admin />} />
          <Route
            path='/sales-manager/login'
            element={!isAuthenticated ? <SalesMangerLogin /> : <Navigate to='/client' replace />}
          />
          <Route path='/journey/:id' element={<ProtectedRoute element={<JourneyBuilder />} />} />
          <Route
            path='/enterprise-selection'
            element={!isEmpty(getClient()) ? <EnterpriseSelection /> : <Navigate to='/client' replace />}
          />
          <Route path='/client' element={<ProtectedRoute element={<ClientIdPage />} />} />
          <Route path='/dashboard' element={<ProtectedRoute element={<Dashboard />} />} />
          <Route path='/client/register' element={<ProtectedRoute element={<ClientRegistration />} />} />
          <Route
            path='/client/verify'
            element={
              getClient().otpContactService ? (
                <ProtectedRoute element={<VerifyCodePage />} />
              ) : (
                <Navigate to='/' replace />
              )
            }
          />
          {infoPagesContent?.termsData && (
            <Route
              path='/terms-and-conditions'
              element={<ProtectedRoute element={<InfoPageComponent data={infoPagesContent.termsData} />} />}
            />
          )}
          {infoPagesContent?.privacyData && (
            <Route
              path='/privacy-policy'
              element={<ProtectedRoute element={<InfoPageComponent data={infoPagesContent.privacyData} />} />}
            />
          )}
          <Route
            path='/client/register/request'
            element={!isEmpty(getClient()) ? <ClientOTPRequest /> : <Navigate to='/' replace />}
          />
          <Route path='/partners/:partnerId' element={<ProtectedRoute element={<ServicesPartnersPage />} />} />
        </Routes>
      </Router>
      {notification.visibility && (
        <Notification
          timer={notification.timer}
          type={notification.type}
          length={notification.length}
          status={notification.status}
          action={notification.action}
          title={notification.title}
          description={notification.description}
        />
      )}
    </>

    // <div className='App'>
    //   {!isEmpty(forms.journey) && <JourneyBuilder svg={svg} />}
    //   {/* <LeadIntegration /> */}
    // </div>
  );
}

export default App;
