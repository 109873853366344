export const languageCode = {
    'Portuguese': 'pt',
    'English': 'en',
}

export const currencyCode = {
    'Euro': 'Euro',
    'Dollar': 'Dollar'
}

export const unitsCode = {
    'Metric System': 'Metric System',
    'Imperial System': 'Imperial System'
}