import { useState, useEffect } from 'react';
// components
import { SVG } from '@assets/svg';
// styles
import './ButtonSwitcher.scss';

const ButtonSwitcher = ({ view2D, view3D, setView2D, setView3D }) => {
  const [isMapSelected, setIsMapSelected] = useState(view2D);
  const [isBoxSelected, setIsBoxSelected] = useState(view3D);

  useEffect(() => {
    setIsMapSelected(view2D);
    setIsBoxSelected(view3D);
  }, [view2D, view3D]);

  return (
    <div className='button-switcher'>
      <button
        className={`button-switcher__button${isMapSelected ? '-selected' : ''}`}
        onClick={() => {
          setIsMapSelected(true);
          setIsBoxSelected(false);
          setView2D();
        }}
      >
        <SVG icon={'action/map'} />
      </button>
      <button
        className={`button-switcher__button${isBoxSelected ? '-selected' : ''}`}
        onClick={() => {
          setIsMapSelected(false);
          setIsBoxSelected(true);
          setView3D();
        }}
      >
        <SVG icon={'action/box'} />
      </button>
    </div>
  );
};

export default ButtonSwitcher;
