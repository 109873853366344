// components
import { SVG } from '@assets/svg';
//styles
import './PreferencesButton.scss';

const PreferencesButton = ({ text, selected, onClickFn }) => {
  return (
    <div className={`preferences-button ${selected === text ? 'selected' : ''}`} onClick={onClickFn}>
      <span>{text}</span>
      {selected === text && <SVG icon={'semantic/check'} />}
    </div>
  );
};

export default PreferencesButton;
