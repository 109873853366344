import { plotsAction } from './plots-slice';
import { getPlots } from '../../services/plots';

export const setPlots = async (dispatch, token) => {
  try {
    const response = await getPlots(token);
    dispatch(plotsAction.setPlots(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
