import { useEffect } from 'react';
import './DetailedCell.scss';
import useRender from '@hooks/useRender';
import useRedux from '@hooks/useRedux';

const DetailedCell = ({
  field,
  item,
  renderCondition,
  shouldTriggerRenderCondition = true,
  isSelected,
  customFn,
  isPlot,
  isMacroplot,
  children
}) => {
  const { itemShouldRender } = useRender();
  const { dispatchSetPlotHover, dispatchSetMacroplotHover, getTracking } = useRedux();

  const handleClick = () => {
    field.onChange(item.id);
    if (customFn) customFn();
  };

  const handleMouseEnter = () => {
    if (isPlot && (!getTracking().plotHover || getTracking().plotHover === null)) dispatchSetPlotHover(item.id);
    if (isMacroplot && (!getTracking().macroplotHover || getTracking().macroplotHover === null))
      dispatchSetMacroplotHover(item.id);
  };

  const handleMouseLeave = () => {
    if (isPlot) dispatchSetPlotHover(null);
    if (isMacroplot) dispatchSetMacroplotHover(null);
  };

  const handleScroll = () => {
    // Manually trigger mouse leave when scrolling happens.
    if (getTracking().plotHover) {
      if (isPlot) dispatchSetPlotHover(null);
    }
    if (getTracking().macroplotHover) {
      if (isMacroplot) dispatchSetMacroplotHover(null);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [getTracking().plotHover, getTracking().macroplotHover]);

  // The component will only render if either:
  // shouldTriggerRenderCondition is false, or
  // itemShouldRender(renderCondition, item) returns true.
  const shouldRender = !shouldTriggerRenderCondition || itemShouldRender(renderCondition, item);

  if (!shouldRender) return null;

  return (
    <div
      className={`detailed-cell ${isSelected ? 'selected' : ''}`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  );
};

export { DetailedCell };
