import { useEffect, useState } from 'react';
// components
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
import Accordion from '@components/ui/Accordion/Accordion';
import Portal from '@components/ui/Overlays/Portal/Portal';
import Dialog from '@components/ui/Overlays/Dialog/Dialog';
import PriceCard from '@components/ui/Cards/PriceCard/PriceCard';
// hooks
import { useFormContext } from 'react-hook-form';
import useRedux from '@hooks/useRedux';
import useModals from '@hooks/useModals';
// styles
import './review.scss';
// utils
import { getArea, getLayoutDetails } from '@utils/areas';

const convertIdToLabel = id => {
  const versionMatch = id.match(/v(\d+)/);
  if (versionMatch) {
    const versionNumber = parseInt(versionMatch[1], 10);
    return `Layout ${versionNumber}`;
  }
};

const Review = ({ formState, journeyObj, setCustomFormStep, setProjectSubmittedPageOpen, children }) => {
  const { questions } = journeyObj;
  const {
    getPlots,
    getMacroplots,
    getHouseTypes,
    getHouses,
    getPacks,
    getTracking,
    getForms,
    dispatchSetDialog,
    getCurrentProject,
    dispatchProjectInfo,
    FORMSTATE,
    PRICING,
    PREFERENCES
  } = useRedux();
  const plots = getPlots();
  const macroplots = getMacroplots();
  const packs = getPacks();
  const pools = getForms().journey?.[PREFERENCES?.get()?.language?.code]?.steps[2].questions[1].answers;
  const parking = getForms().journey?.[PREFERENCES?.get()?.language?.code]?.steps[2].questions[2].answers;
  const nrRooms = getForms().journey?.[PREFERENCES?.get()?.language?.code]?.steps[1].questions[0].answers[0].items;
  const currency = PREFERENCES.get().currency;
  let mastersuites = [];
  let suites = [];
  let socialAreas = [];
  let appliances = [];
  const units = PREFERENCES.get().units;

  const [projectName, setProjectName] = useState(
    getCurrentProject()?.configurationName ?? getCurrentProject()?.configurationDefaultName
  );
  const [isModalOpen, setModalOpen] = useModals();
  // open and close modal
  const toggleModal = () => setModalOpen(!isModalOpen);

  // Update pricing when currency changes
  useEffect(() => {
    PRICING.update({ formState, currency });
  }, [currency]);

  const handleChange = e => {
    setProjectName(e.target.value);
    const currentProjectInfo = structuredClone(getCurrentProject());
    currentProjectInfo.configurationName = e.target.value;
    dispatchProjectInfo(currentProjectInfo);
  };

  if (!FORMSTATE.get('stepsFinished').includes('review')) FORMSTATE.dispatchUpdateStepFinished('review');

  if (getTracking()?.interiorsAreas) {
    Object.keys(getTracking().interiorsAreas).forEach(key => {
      if (key === 'intimate-areas') {
        mastersuites = getTracking().interiorsAreas[key].mastersuites;
        suites = getTracking().interiorsAreas[key].suites;
      } else if (key === 'social-areas') {
        socialAreas = getTracking().interiorsAreas[key];
      } else if (key === 'kitchen') {
        appliances = getTracking().interiorsAreas[key].kitchens[0].appliances.appliances;
      }
    });
  }

  const { watch } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();

  const getLayout = () => {
    const house = getHouses().filter(house => house.id === watcher?.layout.houseLayout);
    const details = getLayoutDetails(house[0].modules?.combinations, units);
    return details;
  };

  const getIntimateAreas = () => {
    let intimateAreas = [];
    let counter = 0;
    if (watcher?.interiors?.intimateAreas?.mastersuite)
      Object.keys(watcher?.interiors.intimateAreas.mastersuite).forEach(key => {
        intimateAreas.push({
          label: mastersuites[counter].suite.title,
          value: packs.filter(pack => pack.id === watcher?.interiors.intimateAreas.mastersuite[key].suite)[0].label
        });
        counter++;
      });
    counter = 0;
    if (watcher?.interiors?.intimateAreas?.suites)
      Object.keys(watcher?.interiors?.intimateAreas?.suites).forEach(key => {
        intimateAreas.push({
          label: suites[counter].suite.title,
          value: packs.filter(pack => pack.id === watcher?.interiors.intimateAreas.suites[key].suite)[0].label
        });
        counter++;
      });
    return intimateAreas;
  };

  const getBathrooms = () => {
    let bathrooms = [];
    let counter = 0;
    bathrooms.push({
      label: socialAreas.filter(area => area.type === 'WC')[0].label,
      value: packs.filter(pack => pack.id === watcher?.interiors.socialAreas.bathroom)[0].label
    });
    if (watcher?.interiors?.intimateAreas?.mastersuite)
      Object.keys(watcher?.interiors.intimateAreas.mastersuite).forEach(key => {
        bathrooms.push({
          label: mastersuites[counter].bathroom.title,
          value: packs.filter(pack => pack.id === watcher?.interiors.intimateAreas.mastersuite[key].bathroom)[0].label
        });
        counter++;
      });
    counter = 0;
    if (watcher?.interiors?.intimateAreas?.suites)
      Object.keys(watcher?.interiors.intimateAreas.suites).forEach(key => {
        bathrooms.push({
          label: suites[counter].bathroom.title,
          value: packs.filter(pack => pack.id === watcher?.interiors.intimateAreas.suites[key].bathroom)[0].label
        });
        counter++;
      });
    return bathrooms;
  };

  return (
    <div className='review'>
      <div className='top-section'>
        <span className='headline'>{questions[0].headline}</span>
        {questions[0].description && <span className='description'>{questions[0].description}</span>}
      </div>
      <TextInput
        label='Configuration name'
        icon='action/tag'
        placeholder={projectName}
        value={projectName}
        onChangeFn={handleChange}
      />
      <div className='review__container'>
        <span>Bespoke Home</span>
        <div className='review__step-container'>
          <div className='review__step-container-header'>
            <span className='review__step-container-title'>Your plot</span>
            <LinkButton
              text='Edit'
              onClickFn={() => {
                setCustomFormStep(0); // TODO dinâmico
              }}
            ></LinkButton>
          </div>
          <div className='review__step-container-line'>
            <span>Macroplot</span>
            <span>{macroplots.filter(macroplot => macroplot.id === watcher?.plot.macroplot)[0].label}</span>
          </div>
          <div className='review__step-container-line'>
            <span>Plot</span>
            <span>
              {plots.filter(plot => plot.id === watcher?.plot.plot)[0].label} -{' '}
              {getArea(units, plots.filter(plot => plot.id === watcher?.plot.plot)[0].area)}
            </span>
          </div>
          <div className='review__step-container-line'>
            <span>Area of Construction Capacity</span>
            <span>{getArea(units, plots.filter(plot => plot.id === watcher?.plot.plot)[0].areaOfConstruction)}</span>
          </div>
        </div>
        <div className='review__step-container'>
          <div className='review__step-container-header'>
            <span className='review__step-container-title'>Your home layout</span>
            <LinkButton
              text='Edit'
              onClickFn={() => {
                setCustomFormStep(1); // TODO dinâmico
              }}
            ></LinkButton>
          </div>
          <div className='review__step-container-line'>
            <span>House Type</span>
            <span>{getHouseTypes().filter(houseType => houseType.id === watcher?.layout.houseType)[0].label}</span>
          </div>
          <div className='review__step-container-line'>
            <span>Bedrooms</span>
            <span>{nrRooms.filter(room => room.id === watcher?.layout.nrRooms)[0].label}</span>
          </div>
          <Accordion
            label={convertIdToLabel(watcher?.layout.houseLayout)}
            description={getLayout().area}
            content={getLayout().layout}
          ></Accordion>
        </div>
        <div className='review__step-container'>
          <div className='review__step-container-header'>
            <span className='review__step-container-title'>Outside looks</span>
            <LinkButton
              text='Edit'
              onClickFn={() => {
                setCustomFormStep(2); // TODO dinâmico
              }}
            ></LinkButton>
          </div>
          {watcher?.exteriors.pool.poolSize === 'none' ? (
            <div className='review__step-container-line'>
              <span>Swimming Pool</span>
              <span>No</span>
            </div>
          ) : (
            <Accordion
              label='Swimming Pool'
              content={[
                {
                  label: 'Size',
                  value: pools[0].items.filter(item => item.id === watcher?.exteriors.pool.poolSize)[0].label
                },
                {
                  label: 'Coating',
                  value: getPacks().find(pack => pack.id === watcher?.exteriors.pool.poolCovering).label
                },
                {
                  label: 'Heated pool',
                  value: pools[2].items.filter(item => item.id === watcher?.exteriors.pool.poolHeated)[0].label
                }
              ]}
            ></Accordion>
          )}
          <div className='review__step-container-line'>
            <span>Parking area</span>
            <span>
              {parking[0].items.filter(item => item.id === watcher?.exteriors.parking.parkingType)[0].label} -{' '}
              {parking[1].items.filter(item => item.id === watcher?.exteriors.parking.parkingSpace)[0].label}
            </span>
          </div>
        </div>
        <div className='review__step-container'>
          <div className='review__step-container-header'>
            <span className='review__step-container-title'>Interiors</span>
            <LinkButton
              text='Edit'
              onClickFn={() => {
                setCustomFormStep(3); // TODO dinâmico
              }}
            ></LinkButton>
          </div>
          <Accordion
            label='Social Area'
            content={[
              {
                label: 'Theme',
                value: packs.filter(pack => pack.id === watcher?.interiors.socialAreas.livingRoom)[0].label
              }
            ]}
          />
          <Accordion
            label='Kitchen'
            content={[
              {
                label: 'Theme',
                value: packs.filter(pack => pack.id === watcher?.interiors.kitchen.kitchen.kitchen)?.[0]?.label
              },
              {
                label: 'Kitchen Appliances',
                value: appliances.filter(
                  appliance => appliance.id === watcher?.interiors.kitchen.kitchen.appliance
                )?.[0]?.label
              }
            ]}
          />
          <Accordion label='Intimate Areas' content={getIntimateAreas()} />
          <Accordion label='Bathrooms' content={getBathrooms()} />
        </div>
      </div>
      <PriceCard></PriceCard>

      {isModalOpen && (
        <Portal>
          <Dialog
            type={getTracking().dialog.type}
            id={getTracking().dialog.id}
            projectName={projectName}
            onClose={() => {
              toggleModal();
              dispatchSetDialog({ open: false, type: '', id: '' });
            }}
            setCustomFormStep={setCustomFormStep}
            setProjectSubmittedPageOpen={setProjectSubmittedPageOpen}
          ></Dialog>
        </Portal>
      )}
    </div>
  );
};

export default Review;
