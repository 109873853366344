import React from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useRedux from '@hooks/useRedux';

const ProtectedRoute = ({ element: Component }) => {
  const { getIsAuthenticated } = useRedux();
  const isAuthenticated = getIsAuthenticated();

  return isAuthenticated ? Component : <Navigate to='/sales-manager/login' />;
};

export default ProtectedRoute;
