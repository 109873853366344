import { useEffect, useState } from 'react';
// components
import Map from '@components/image-container/map/Map';
import Slideshow from '@components/image-container/slideshow/Slideshow';
import ZoomControls from '@components/ui/ZoomControls/ZoomControls';
import Pill from '@components/ui/Pill/Pill';
import ButtonSwitcher from '@components/ui/Buttons/ButtonSwitcher/ButtonSwitcher';
// hooks
import useRedux from '@hooks/useRedux';
import { useNavigate } from 'react-router-dom';
// assets
import review1 from '@assets/img/review1.png';
import review2 from '@assets/img/review2.png';
import review3 from '@assets/img/review3.png';
import review4 from '@assets/img/review4.png';
import review5 from '@assets/img/review5.png';
import Photo from './photo/Photo';
// utils
import { isArray } from '@utils/utils';
import { isEmpty } from '@utils/functions';
// styles
import './index.scss';
// utils
import { clearFilter, getLabels } from '@utils/filters';
import { SVG } from '@assets/svg';

/**
 * ImageContainer component that renders different types of content based on the current step ID.
 *
 * This component uses the `useRedux` hook to get the current step ID and images.
 * It conditionally renders a Map, Slideshow, or Photo component based on the current step ID.
 *
 * @component
 * @returns {JSX.Element} The rendered component based on the current step ID.
 */
const ImageContainer = () => {
  const navigate = useNavigate();
  const {
    getImages,
    getCurrentStepId,
    getFilters,
    dispatchFilters,
    getHouseTypes,
    getTracking,
    getHouses,
    dispatchResetFilters,
    dispatchProjectInfo,
    FORMSTATE,
    PREFERENCES
  } = useRedux();

  const currentStepId = getCurrentStepId();
  const plots = getImages().svg.plots;
  const macroplots = getImages().svg.macroplots;
  const [hoveredPlot, setHoveredPlot] = useState(null);
  const [hoveredMacroplot, setHoveredMacroplot] = useState(null);
  const currentImage = getImages().current;

  const [zoom, setZoom] = useState(null);
  const [view2D, setView2D] = useState(false);
  const [view3D, setView3D] = useState(true);
  const filters = getFilters();
  const labels = getLabels(getHouseTypes(), PREFERENCES.get().units, filters);
  const currentQuestionId = getTracking().currentQuestionId;
  const floorplan = getHouses()?.filter(house => house.id === getTracking()?.houseName)[0]?.floorPlan;

  const imagesReview = [review1, review2, review3, review4, review5];
  const fallbackImagePath = 'img/macroplotA.png';

  const toRender = () => {
    if (currentStepId === 'plot')
      return (
        <Map
          plots={plots}
          macroplots={macroplots}
          zoom={zoom}
          hoveredPlot={hoveredPlot}
          hoveredMacroplot={hoveredMacroplot}
        />
      );
    if (currentStepId === 'review' && isArray(imagesReview) && !isEmpty(imagesReview))
      return <Slideshow className='slideshow' images={imagesReview} autoSlide />;
    if (view2D && currentStepId !== 'plot' && currentStepId !== 'review' && currentQuestionId !== 'houseLayout') {
      if (isArray(floorplan) && !isEmpty(floorplan)) return <Slideshow className='slideshow' images={floorplan} />;
      else return <Photo image={floorplan} fallbackImagePath={fallbackImagePath} />;
    } else if (view3D && currentStepId !== 'plot' && currentStepId !== 'review') {
      if (currentStepId !== 'plot' && currentStepId !== 'review' && isArray(currentImage) && !isEmpty(currentImage))
        return <Slideshow className='slideshow' images={currentImage} />;
      if (currentStepId !== 'plot' && currentStepId !== 'review')
        return <Photo image={currentImage} fallbackImagePath={fallbackImagePath} />;
    }
  };

  useEffect(() => {
    setHoveredPlot(getTracking().plotHover || null);
  }, [getTracking().plotHover]);

  useEffect(() => {
    setHoveredMacroplot(getTracking().macroplotHover || null);
  }, [getTracking().macroplotHover]);

  useEffect(() => {
    setView2D(false);
    setView3D(true);
  }, [currentStepId, currentQuestionId]);

  // clean zoom const to allow zooming in and out
  useEffect(() => {
    if (zoom) setZoom(null);
  }, [zoom]);

  return (
    <>
      <div className='image-container-header'>
        <div className='image-container-header-left'>
          <button
            className='logo-button'
            onClick={() => {
              // Reset when clicking on the logo
              FORMSTATE.reset();
              dispatchResetFilters();
              dispatchProjectInfo(null);
              navigate('/');
            }}
          >
            <SVG icon='logo/bespokesmall_white' />
          </button>
          {currentStepId === 'plot' && (
            <div className='filters-pills'>
              {Object.keys(labels).map(key => {
                return isArray(labels[key]) ? (
                  labels[key].map((filter, index) => {
                    return (
                      <Pill
                        label={filter}
                        onClickFn={() => dispatchFilters({ filters: clearFilter(filters, key, filters[key][index]) })}
                      />
                    );
                  })
                ) : (
                  <Pill
                    label={labels[key]}
                    onClickFn={() => dispatchFilters({ filters: clearFilter(filters, key, filters[key]) })}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className='image-container-header-right'>
          {currentStepId === 'plot' && <SVG icon='logo/compass' />}
          {currentStepId !== 'review' && view2D && <SVG icon='logo/compass' />}
          {currentStepId === 'plot' && (
            <ZoomControls zoomIn={() => setZoom('zoomIn')} zoomOut={() => setZoom('zoomOut')} />
          )}
        </div>
      </div>
      {toRender()}
      {currentStepId !== 'plot' && currentStepId !== 'review' && currentQuestionId !== 'houseLayout' && (
        <div className='image-container-footer'>
          <ButtonSwitcher
            view2D={view2D}
            view3D={view3D}
            setView2D={() => {
              setView2D(true);
              setView3D(false);
            }}
            setView3D={() => {
              setView2D(false);
              setView3D(true);
            }}
          />
        </div>
      )}
    </>
  );
};

export default ImageContainer;
