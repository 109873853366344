import { useState } from 'react';
import useRedux from './useRedux';
import useFilters from './useFilters';
import { useFormContext } from 'react-hook-form';
import { setPrevent } from '@utils/preventions';

const useDefaultValues = () => {
  const {
    dispatchUpdateTracking,
    getForms,
    getPlots,
    getTracking,
    dispatchInteriorsAreas,
    getHouses,
    getPacks,
    PREFERENCES
  } = useRedux();
  const { getHouseTypeByPlotAndTypology, getHouseLayoutsByPlotTypologyHouseType } = useFilters();
  const { watch, getValues, setValue } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();
  const FORM_STEPS = getForms().journey?.[PREFERENCES?.get()?.language?.code].steps;
  const [lastPoolSize, setLastPoolSize] = useState(null);

  const setAnswerValue = (currentStepId, questionId, firstAnswerItemId) => {
    const selectedValues = getValues();
    selectedValues[`${currentStepId}`][`${questionId}`] = firstAnswerItemId;
    setValue(`${currentStepId}.${questionId}`, firstAnswerItemId);
    if (['macroplot', 'nrRooms', 'houseType'].includes(questionId)) {
      dispatchUpdateTracking({ idToUpdate: questionId, updatedObject: firstAnswerItemId });
    }
  };

  const getDefaultValue = question => {
    const answers = question?.answers;
    return answers?.[0]?.items?.[0]?.id;
  };

  const updateTypologyHouseTypeHouseLayout = (selectedValues, nrRooms, currentStepId) => {
    console.log('handle step 2');
    const selectedPlot = selectedValues.plot?.plot;
    const STEP_QUESTIONS = FORM_STEPS[1]?.questions || [];
    const houseTypeQuestion = STEP_QUESTIONS[1];

    const filteredHouseTypes = getHouseTypeByPlotAndTypology(selectedPlot, nrRooms);
    const allHouseTypes = houseTypeQuestion?.answers?.[0]?.items?.map(({ id }) => id) || [];
    const newHouseType = allHouseTypes.find(type => filteredHouseTypes.includes(type));

    const newHouseLayout = getHouseLayoutsByPlotTypologyHouseType(selectedPlot, nrRooms, newHouseType);

    selectedValues.layout.nrRooms = nrRooms;
    selectedValues.layout.houseType = newHouseType;
    selectedValues.layout.houseLayout = newHouseLayout[0].id ?? undefined;

    setAnswerValue(currentStepId, 'nrRooms', selectedValues.layout.nrRooms);
    setAnswerValue(currentStepId, 'houseType', selectedValues.layout.houseType);
    setPrevent('houseType');
    setAnswerValue(currentStepId, 'houseLayout', selectedValues.layout.houseLayout);
    dispatchUpdateTracking({ idToUpdate: 'houseName', updatedObject: newHouseLayout[0].id ?? undefined });
    // request interior areas, since houseLayout has changed
    dispatchInteriorsAreas(newHouseLayout[0].id ?? undefined);
  };

  const handleLayoutStep = (recommendation, selectedValues, currentStepId) => {
    if (recommendation) {
      const { id, typology, houseType, modules } = recommendation;
      selectedValues.layout.nrRooms = typology;
      selectedValues.layout.houseType = houseType;
      // selectedValues.layout.houseLayout = modules;
      selectedValues.layout.houseLayout = id;
      setAnswerValue(currentStepId, 'nrRooms', selectedValues.layout.nrRooms);
      setAnswerValue(currentStepId, 'houseType', selectedValues.layout.houseType);
      setPrevent('houseType');
      // setAnswerValue(currentStepId, 'houseLayout', selectedValues.layout.houseLayout);
      setAnswerValue(currentStepId, 'houseLayout', id);

      dispatchUpdateTracking({ idToUpdate: 'houseName', updatedObject: id });
      dispatchInteriorsAreas(id ?? undefined);
      return true;
    }
    console.log('step 1 logic');
    // If no recommendation, use default logic
    const nrRoomsQuestion = FORM_STEPS[1]?.questions[0];
    const nrRooms = getDefaultValue(nrRoomsQuestion);

    updateTypologyHouseTypeHouseLayout(selectedValues, nrRooms, 'layout');
    return;
  };

  const updateHouseLayout = (selectedPlot, houseType, nrRooms, currentStepId) => {
    const newHouseLayout = getHouseLayoutsByPlotTypologyHouseType(selectedPlot, nrRooms, houseType);
    setAnswerValue(currentStepId, 'houseLayout', newHouseLayout[0].id ?? undefined);
    setAnswerValue(currentStepId, 'houseType', houseType);
    dispatchUpdateTracking({ idToUpdate: 'houseName', updatedObject: newHouseLayout[0].id ?? undefined });
    // request interior areas, since houseLayout has changed
    dispatchInteriorsAreas(newHouseLayout[0].id ?? undefined);
  };

  const handleExteriorsStep = (stepQuestions, currentStepId) => {
    // Handle 'Exteriors' step special case
    if (currentStepId !== 'exteriors') return;
    stepQuestions.forEach(question => {
      if (!question.answers) return;
      const questionId = question.id;
      if (questionId === 'pool') {
        const poolAnswers = question.answers;

        poolAnswers.forEach(answer => {
          const answerId = answer.id;
          if (answerId === 'poolSize') {
            const selectedPlot = watcher?.plot?.plot;
            const houseName = getTracking().houseName;
            const pool = getPlots()
              .find(plot => plot.id === selectedPlot)
              .pools.find(entry => entry.house === houseName);

            const maxPoolSize = pool.maxPoolSize;
            setValue(`${currentStepId}.${questionId}.${answerId}`, maxPoolSize);
          } else if (answerId === 'poolCovering') {
            const packs = getPacks().filter(pack => pack.divisionType === 'Piscina');
            setValue(`${currentStepId}.${questionId}.${answerId}`, packs[0].id);
          } else {
            const firstAnswerItemId = answer?.items?.[0]?.id;
            setValue(`${currentStepId}.${questionId}.${answer.id}`, firstAnswerItemId);
          }
        });
      } else {
        question.answers.forEach(answer => {
          const parking = getHouses().find(house => house.id === getTracking().houseName).nrParking;
          const firstAnswerItemId = answer?.items?.[0]?.id;
          if (answer.id === 'parkingSpace') {
            setValue(`${currentStepId}.${questionId}.${answer.id}`, `${parking}`);
          } else {
            setValue(`${currentStepId}.${questionId}.${answer.id}`, firstAnswerItemId);
          }
        });
      }
    });
    return;
  };

  const handleInteriorsStep = currentStepId => {
    console.log('handle interiors');
    const { interiorsAreas } = getTracking();
    const interiorsAreasFormatted = {
      socialAreas: interiorsAreas['social-areas'],
      kitchen: interiorsAreas['kitchen'],
      intimateAreas: interiorsAreas['intimate-areas']
    };

    Object.keys(interiorsAreasFormatted).forEach(key => {
      if (key === 'socialAreas') {
        interiorsAreasFormatted[key].forEach(sa => {
          setValue(`${currentStepId}.${key}.${sa.controllerId}`, sa.packs[0]);
        });
      }
      if (key === 'kitchen') {
        interiorsAreasFormatted[key].kitchens.forEach(k => {
          setValue(`${currentStepId}.${key}.${k.kitchen.controllerId}`, k.kitchen.packs[0]);
          setValue(`${currentStepId}.${key}.${k.appliances.controllerId}`, k.appliances.appliances[0].id);
        });
      }
      if (key === 'intimateAreas') {
        // interiorsAreasFormatted[key] = mastersuites[] / suites[]
        Object.keys(interiorsAreasFormatted[key]).forEach(intimate => {
          const intimateArea = interiorsAreasFormatted[key][intimate];
          intimateArea.forEach(ia => {
            setValue(`${currentStepId}.${key}.${ia.suite.controllerId}`, ia.suite.packs[0]);
            setValue(`${currentStepId}.${key}.${ia.bathroom.controllerId}`, ia.bathroom.packs[0]);
          });
        });
      }
    });
  };

  const setDefaultValues = (currentStepNr, currentStepId) => {
    // dont apply default values on Step 1
    if (currentStepId === 'plot') return;
    const stepQuestions = FORM_STEPS[currentStepNr]?.questions || [];
    const selectedValues = getValues();

    // Handle 'Layout' step special case with selected plot
    if (currentStepNr === 1 && selectedValues.plot.plot) {
      const selectedPlot = selectedValues.plot.plot;
      const recommendation = getPlots().find(plot => plot.id === selectedPlot)?.recommendation;
      handleLayoutStep(recommendation, selectedValues, currentStepId);
      return;
    }

    // Handle 'Exteriors' step special case for pool
    if (currentStepNr === 2) {
      handleExteriorsStep(stepQuestions, currentStepId);
      return;
    }

    if (currentStepNr === 3) {
      handleInteriorsStep(currentStepId);
      return;
    }

    // Process step questions
    stepQuestions.forEach(({ id: questionId, answers = [] }) => {
      if (answers.length === 0) return;

      answers.forEach(answer => {
        const firstAnswerItemId = answer?.items?.[0]?.id;
        if (!firstAnswerItemId) {
          console.log(`Answer for ${answer.id} does not have valid items`);
          return;
        }

        if (answers.length > 1) {
          setValue(`${currentStepId}.${questionId}.${answer.id}`, firstAnswerItemId);
        } else {
          setAnswerValue(currentStepId, questionId, firstAnswerItemId);
        }
      });
    });
  };

  const setDefaultValuesAfterChange = (currentStepNr, changedElement) => {
    console.log('setDefaultValuesAfterChange');
    const selectedValues = getValues();
    // Handle macroplot changes on step 1
    if (currentStepNr === 0 && changedElement.id === 'macroplot') {
      const selectedMacroplot = selectedValues?.plot?.macroplot;
      const question = FORM_STEPS[0]?.questions?.find(({ id }) => id === changedElement.id);
      if (!question) return;

      const answers = question?.answers?.[0]?.items || [];
      const firstValue = answers.find(({ id }) => id === selectedMacroplot)?.plots?.[0];
      console.log('setDefaultValuesAfterChange firstvalue', firstValue);
      if (firstValue) {
        selectedValues.plot.macroplot = changedElement.newValue;
        selectedValues.plot.plot = firstValue;
        setValue('plot.macroplot', selectedValues.plot.macroplot);
        setValue('plot.plot', selectedValues.plot.plot);
      }
      return;
    }

    // Setting default values if nrRooms changes (Step 02)
    if (currentStepNr === 1) {
      if (changedElement.id === 'nrRooms') {
        updateTypologyHouseTypeHouseLayout(selectedValues, changedElement.newValue, 'layout');
        return;
      }
      if (changedElement.id === 'houseType') {
        const { layout } = selectedValues;
        const selectedPlot = selectedValues?.plot?.plot;
        updateHouseLayout(selectedPlot, changedElement.newValue, layout.nrRooms, 'layout');
        return;
      }
    }
  };

  return { setDefaultValues, setDefaultValuesAfterChange };
};

export default useDefaultValues;
