import { createSlice } from '@reduxjs/toolkit';

const currentProjectSlice = createSlice({
  name: 'currentProject',
  initialState: null,
  reducers: {
    setCurrentProject: (state, action) => {
      return action.payload;
    }
  }
});

const projectAction = currentProjectSlice.actions;
export { currentProjectSlice, projectAction };
