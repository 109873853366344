import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';

export const sendVerificationCode = async (contact, channel) => {
  const HEADERS = {
    accept: '*/*'
  };

  const requestObj = {
    method: 'post',
    url: `${getFullEndpoint({ microservice: 'otp', service: 'sendVerificationCode' })}`,
    headers: HEADERS,
    data: { contact, channel }
  };

  const response = await axios.request(requestObj);
  return response;
};

export const checkVerificationCode = async (contact, code) => {
  const HEADERS = {
    accept: '*/*'
  };

  const requestObj = {
    method: 'post',
    url: `${getFullEndpoint({ microservice: 'otp', service: 'checkVerificationCode' })}`,
    headers: HEADERS,
    data: { contact, code }
  };

  const response = await axios.request(requestObj);
  return response.data;
};
