import { createSlice } from '@reduxjs/toolkit';

const formsSlice = createSlice({
  name: 'forms',
  initialState: {},
  reducers: {
    setForm: (state, action) => {
      // const { forms } = action.payload;
      // console.log('FORMS', forms);
      return { ...action.payload };
    }
  }
});

const formsActions = formsSlice.actions;
export { formsSlice, formsActions };
