import { getArea } from '@utils/areas';

export const getNrRoomsFilteredByArea = (plotsByArea, nrRoomsFiltered) => {
  nrRoomsFiltered = nrRoomsFiltered.filter(room =>
    plotsByArea.some(plot => plot.houses.some(house => house.includes(room.id)))
  );
  return nrRoomsFiltered;
};

export const getHouseTypesFilteredByArea = (plotsByArea, houseTypesFiltered) => {
  houseTypesFiltered = houseTypesFiltered.filter(houseType =>
    plotsByArea.some(plot => plot.houses.some(house => house.includes(houseType.id)))
  );
  return houseTypesFiltered;
};

export const getNrRoomsFilteredByHouseType = (housesFilteredByHouseType, nrRoomsFiltered, plotsByArea) => {
  const housesFilteredByPlots = housesFilteredByHouseType.filter(house =>
    plotsByArea.some(plot => plot.houses.includes(house.id))
  );
  nrRoomsFiltered = nrRoomsFiltered.filter(room => housesFilteredByPlots.some(house => house.typology === room.id));
  return nrRoomsFiltered;
};

export const getHouseTypesFilteredByNrRooms = (housesFilteredByTypology, houseTypesFiltered, plotsByArea) => {
  const housesFilteredByPlots = housesFilteredByTypology.filter(house =>
    plotsByArea.some(plot => plot.houses.includes(house.id))
  );
  houseTypesFiltered = houseTypesFiltered.filter(houseType =>
    housesFilteredByPlots.some(house => house.houseType === houseType.id)
  );
  return houseTypesFiltered;
};

export const getAreasFilteredByNrRoomsAndHouseType = (
  filterState,
  plotsFiltered,
  housesByTypology,
  housesByHouseType
) => {
  if (filterState?.bedrooms?.length > 0 && filterState?.houseType?.length === 0) {
    plotsFiltered = plotsFiltered.filter(plot => housesByTypology.some(house => plot.houses.includes(house.id)));
  } else if (filterState?.houseType?.length > 0 && filterState?.bedrooms?.length === 0) {
    plotsFiltered = plotsFiltered.filter(plot => housesByHouseType.some(house => plot.houses.includes(house.id)));
  } else if (filterState?.bedrooms?.length > 0 && filterState?.houseType?.length > 0) {
    const housesFilteredByTypologyAndHouseType = housesByTypology.filter(house =>
      filterState?.houseType?.some(houseType => house.houseType === houseType)
    );
    plotsFiltered = plotsFiltered.filter(plot =>
      housesFilteredByTypologyAndHouseType.some(house => plot.houses.includes(house.id))
    );
  }
  return plotsFiltered;
};

export const clearFilter = (filterState, key, label) => {
  let filters = { ...filterState };
  if (key === 'plotMinArea') filters.plotMinArea = 'Min';
  else if (key === 'plotMaxArea') filters.plotMaxArea = 'Max';
  else if (key === 'bedrooms') {
    filters.bedrooms.filter(bedroom => bedroom !== label).length > 0
      ? (filters.bedrooms = filters.bedrooms.filter(bedroom => bedroom !== label))
      : delete filters.bedrooms;
  } else if (key === 'houseType') {
    filters.houseType.filter(type => type !== label).length > 0
      ? (filters.houseType = filters.houseType.filter(type => type !== label))
      : delete filters.houseType;
  } else if (key === 'minPrice') delete filters.minPrice;
  else if (key === 'maxPrice') delete filters.maxPrice;
  else if (key === 'sortBy') filters.sortBy = 'Name';
  return filters;
};

const extractNumber = label => {
  const match = label.match(/\d+/);
  return match ? parseInt(match[0]) : null;
};

export const getLabels = (houseTypes, units, filters) => {
  const labels = {};
  if (filters.plotMinArea && filters.plotMinArea !== 'Min')
    labels.plotMinArea = `Min size ${getArea(units, filters.plotMinArea)}`;
  if (filters.plotMaxArea && filters.plotMaxArea !== 'Max')
    labels.plotMaxArea = `Max size ${getArea(units, filters.plotMaxArea)}`;
  if (filters.bedrooms) labels.bedrooms = filters.bedrooms.map(bedroom => `${extractNumber(bedroom)} Bedrooms`);
  if (filters.houseType)
    labels.houseType = filters.houseType.map(type => `${houseTypes.filter(house => house.id === type)[0].label}`);
  // if (filters.minPrice && filters.maxPrice) labels.priceRange = `Price range ${filters.minPrice}-${filters.maxPrice}M€`;
  if (filters.sortBy && filters.sortBy !== 'Name') labels.sortBy = `Sort by ${filters.sortBy}`;
  return labels;
};
