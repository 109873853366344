import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useRedux from '@hooks/useRedux';
//styles
import './homepage.scss';
//components
import VideoComponent from './VideoComponent';
import Header from '@components/ui/Header/Header';
//utils
import Button from '@components/ui/Buttons/Button/Button';
import imagem from './first_frame.jpg';

const Homepage = ({ homepageContent }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const { dispatchResetTracking, PREFERENCES } = useRedux();
  const [content, setContent] = useState(null);
  const buttonType = content?.actionButton?.type?.split('-')?.[0];
  const buttonMode = content?.actionButton?.type?.split('-')?.[1];

  const navigate = useNavigate();

  const goToClientPage = () => {
    navigate('/client');
  };

  useEffect(() => {
    if (PREFERENCES.get().languageOptions && PREFERENCES.get().language === null)
      PREFERENCES.updateLanguage(PREFERENCES.get().languageOptions.find(option => option.isDefault));
    if (PREFERENCES.get().language !== null) setContent(homepageContent?.[0]?.[PREFERENCES?.get()?.language?.code]);
  }, [PREFERENCES]);

  useEffect(() => {
    dispatchResetTracking();
    PREFERENCES.reset();
  });

  console.log('homepageContent', homepageContent);

  //TODO i18n

  return homepageContent && homepageContent.length > 0 ? (
    <div className='homepage-container'>
      <Header shouldDisplay appearDashboard />
      <div className='content-section'>
        <div className='title-wrapper'>
          <span className='homepage-title1'>{content?.title1}</span>
          <span className='homepage-title2'>{content?.title2}</span>
        </div>
        <div>
          <Button
            primary={buttonType === 'PRIMARY'}
            secondary={buttonType === 'SECONDARY'}
            ghost={buttonType === 'GHOST'}
            isDark={buttonMode === 'DARK'}
            text={content?.actionButton?.label}
            onClickFn={goToClientPage}
          />
        </div>
      </div>
      {!isVideoLoaded && <img className='background-image' src={imagem} />}
      {content?.backgroundVideo?.url && (
        <VideoComponent
          videoUrl={content?.backgroundVideo?.url}
          setVideoIsReady={setIsVideoLoaded}
          metaData={content?.backgroundVideo}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default Homepage;
