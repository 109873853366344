import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const authSlice = createSlice({
  name: 'auth',
  initialState: !!Cookies.get('sessionId') && !!Cookies.get('salesManager'),
  reducers: {
    setIsAuthenticated: (state, action) => {
      return action.payload;
    }
  }
});

const authAction = authSlice.actions;
export { authSlice, authAction };
