// hooks
import useRender from '@hooks/useRender';
import useRedux from '@hooks/useRedux';
// components
import DetailedCell from '@components/ui/Cells/DetailedCell';
import State from '@components/ui/State/State';
// utils
import { PLOT_STATUS } from '@utils/enums';
import { getArea, getLayoutDetails } from '@utils/areas';

const Integration = ({ type, field, item, renderCondition, shouldTriggerRenderCondition, controllerId }) => {
  const isSelected = useRender().isElementSelected({ controllerId, item });
  const { dispatchInteriorsAreas, dispatchSetRecommendation, PREFERENCES } = useRedux();

  const LayoutDetails = ({ title, data }) => {
    const getItem = item => {
      const area = getArea(PREFERENCES.get().units, item.area);
      return (
        <DetailedCell.Row key={item.label}>
          <DetailedCell.Description medium>{item.label}</DetailedCell.Description>
          <DetailedCell.Description regular>{area}</DetailedCell.Description>
        </DetailedCell.Row>
      );
    };

    return (
      <div className='layout-area'>
        <DetailedCell.Row>
          <DetailedCell.Description title>{title}</DetailedCell.Description>
        </DetailedCell.Row>

        <div className='idented-area'>{data.map(item => getItem(item))}</div>
      </div>
    );
  };

  const getById = {
    'show-macroplots': () => {
      return (
        <DetailedCell.Root
          field={field}
          item={item}
          renderCondition={renderCondition}
          isSelected={isSelected}
          isMacroplot
        >
          <DetailedCell.Title>{item.label}</DetailedCell.Title>
        </DetailedCell.Root>
      );
    },
    'show-plots': () => {
      const { status } = item;
      const area = getArea(PREFERENCES.get().units, item.area);
      const areaOfConstruction = getArea(PREFERENCES.get().units, item.areaOfConstruction);
      if (status !== PLOT_STATUS.Sold) {
        return (
          <DetailedCell.Root
            field={field}
            item={item}
            renderCondition={renderCondition}
            shouldTriggerRenderCondition={shouldTriggerRenderCondition}
            isSelected={isSelected}
            customFn={() => dispatchSetRecommendation(item.recommendation)}
            isPlot
          >
            {status === PLOT_STATUS.InNegotiation ? (
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <DetailedCell.Title>{item.label}</DetailedCell.Title>
                <State type='warning' text='IN NEGOTIATION' />
              </div>
            ) : (
              <DetailedCell.Title>{item.label}</DetailedCell.Title>
            )}
            <DetailedCell.Row>
              <DetailedCell.Description medium>Area</DetailedCell.Description>
              <DetailedCell.Description regular>{area}</DetailedCell.Description>
            </DetailedCell.Row>
            <DetailedCell.Row>
              <DetailedCell.Description medium>Available Area</DetailedCell.Description>
              <DetailedCell.Description regular>{areaOfConstruction}</DetailedCell.Description>
            </DetailedCell.Row>
          </DetailedCell.Root>
        );
      }
    },
    'show-houseLayout': () => {
      const details = getLayoutDetails(item?.modules, PREFERENCES.get().units);
      const area = details.area;
      const expandedInformation = (
        <>
          <LayoutDetails title='Social Areas' data={details.social} />
          <LayoutDetails title='Intimate Areas' data={details.intimate} />
        </>
      );

      const onLayoutClick = () => {
        dispatchInteriorsAreas(item.id);
      };

      return (
        <DetailedCell.Root field={field} item={item} isSelected={isSelected} customFn={() => onLayoutClick()}>
          <div className='layout-details'>
            <div className='layout'>
              <DetailedCell.Title>Layout {item.index}</DetailedCell.Title>
              <DetailedCell.Row>
                <DetailedCell.Description regular>{area}</DetailedCell.Description>
              </DetailedCell.Row>
            </div>
            {isSelected && expandedInformation}
          </div>
        </DetailedCell.Root>
      );
    }
  };

  return getById[type]();
};

export default Integration;
