import { useState } from 'react';
// components
import Button from '@components/ui/Buttons/Button/Button';
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import PreferencesButton from '@components/ui/Buttons/PreferencesButton/PreferencesButton';
// hooks
import useRedux from '@hooks/useRedux';
// styles
import './Preferences.scss';
//services
import { updatePreferences } from '@services/projects';
//utils
import { languageCode, currencyCode, unitsCode } from '@utils/preferences';

const Preferences = ({ onClose }) => {
  const { PREFERENCES, dispatchNotification, dispatchProjectInfo, getCurrentProject } = useRedux();
  const [selectedLanguage, setSelectedLanguage] = useState(
    Object.entries(languageCode).find(([_, value]) => value === PREFERENCES.get()?.language?.code)?.[0]
  );
  const [selectedCurrency, setSelectedCurrency] = useState(PREFERENCES.get().currency);
  const [selectedUnits, setSelectedUnits] = useState(PREFERENCES.get().units);

  const ProjectDefaultName = {
    pt: 'Projeto Torre',
    en: 'Project Torre'
  };

  return (
    <>
      <div className='preferences-header'>
        <IconButton size={'small'} onImageBg={false} icon={'semantic/close'} onClickFn={onClose} />
      </div>
      <div className='preferences-content'>
        <span className='preferences-title'>Preferences</span>
        <div className='preferences-section'>
          <span className='preferences-section-title'>Language</span>
          <div className='preferences-section-content'>
            <PreferencesButton
              text='Portuguese'
              selected={selectedLanguage}
              onClickFn={() => setSelectedLanguage('Portuguese')}
            />
            <PreferencesButton
              text='English'
              selected={selectedLanguage}
              onClickFn={() => setSelectedLanguage('English')}
            />
          </div>
        </div>
        <div className='preferences-section'>
          <span className='preferences-section-title'>Currency</span>
          <div className='preferences-section-content'>
            <PreferencesButton text='Euro' selected={selectedCurrency} onClickFn={() => setSelectedCurrency('Euro')} />
            <PreferencesButton
              text='Dollar'
              selected={selectedCurrency}
              onClickFn={() => setSelectedCurrency('Dollar')}
            />
          </div>
        </div>
        <div className='preferences-section'>
          <span className='preferences-section-title'>Units</span>
          <div className='preferences-section-content'>
            <PreferencesButton
              text='Metric System'
              selected={selectedUnits}
              onClickFn={() => setSelectedUnits('Metric System')}
            />
            <PreferencesButton
              text='Imperial System'
              selected={selectedUnits}
              onClickFn={() => setSelectedUnits('Imperial System')}
            />
          </div>
        </div>
      </div>
      <div className='preferences-footer'>
        <Button secondary text={'Cancel'} onClickFn={onClose} />
        <Button
          primary
          text={'Apply'}
          onClickFn={async () => {
            try {
              //Update language and default project name to the correct language
              localStorage.setItem(
                'lang',
                JSON.stringify(
                  PREFERENCES.get().languageOptions.find(option => option.code === languageCode?.[selectedLanguage])
                )
              );
              PREFERENCES.updateLanguage(
                PREFERENCES.get().languageOptions.find(option => option.code === languageCode?.[selectedLanguage])
              );
              PREFERENCES.updateCurrency(selectedCurrency);
              PREFERENCES.updateUnits(selectedUnits);
              const currentDefaultName = getCurrentProject().configurationDefaultName;
              console.log('currentDefaultName', currentDefaultName);
              const match = currentDefaultName.match(/\d+$/);
              const numberOnDefaultName = match ? match[0] : null;
              const response = await updatePreferences({
                projectId: getCurrentProject().id,
                preferredLanguage: languageCode?.[selectedLanguage],
                preferredCurrency: currencyCode?.[selectedCurrency],
                preferredUnits: unitsCode?.[selectedUnits],
                configurationDefaultName:
                  selectedLanguage !== PREFERENCES.get().language.name.split(' ')[0] &&
                  !getCurrentProject().configurationName
                    ? `${ProjectDefaultName?.[languageCode?.[selectedLanguage]]} ${numberOnDefaultName}`
                    : getCurrentProject().configurationDefaultName,
                configurationName: getCurrentProject().configurationName ?? null
              });

              dispatchProjectInfo(response?.updatedProject);
            } catch (error) {
              console.error('Error updating preferences', error);
              dispatchNotification({
                timer: true,
                type: 'banner',
                status: 'error',
                title: 'Error updating preferences'
              });
            }

            onClose();
          }}
        />
      </div>
    </>
  );
};

export default Preferences;
