import { imagesAction } from './image-slice';
import { getSVG } from '@services/images';

export const setImage = async ({ dispatch, hash, imageUrl }) => {
  try {
    dispatch(imagesAction.setImage({ hash, imageUrl }));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};

export const setSVG = async ({ dispatch }) => {
  try {
    const SVGresponse = await getSVG();
    dispatch(imagesAction.setSVG({ svg: SVGresponse }));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
