import { useEffect, useState } from 'react';
// hooks
import { useNavigate } from 'react-router-dom';
import useRedux from '@hooks/useRedux';
// components
import Button from '@components/ui/Buttons/Button/Button';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
import PasswordInput from '@components/ui/Inputs/PasswordInput/PasswordInput';
// services
import { login } from '@services/sales-manager';
// utils
import { setSession, setSalesManager, setSalesManagerId } from '@utils/auth';
import { SVG } from '@assets/svg';
import { isEmpty } from '@utils/functions';
// styling
import salesManagerLogin from '@assets/img/salesManagerLogin.png';
import './login.scss';
//translations
import { useTranslation } from 'react-i18next';

const SalesMangerLogin = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ username: '', password: '' });
  const { t } = useTranslation();

  const { dispatchNotification, dispatchResetTracking, dispatchResetFilters, FORMSTATE, dispatchIsAuthenticated } = useRedux();

  const navigate = useNavigate();

  // Handle input changes
  const handleChange = e => {
    const { name, value } = e.target;
    setCredentials(prev => ({ ...prev, [name]: value }));
  };

  const handleClearUsername = () => {
    setCredentials(prev => ({ ...prev, username: '' }));
  };

  useEffect(() => {
    dispatchResetTracking();
    FORMSTATE.reset();
    dispatchResetFilters();
  }, []);

  const handleLogin = async e => {
    e.preventDefault();
    setError({ username: '', password: '' });
    if (isEmpty(credentials.username)) setError(prev => ({ ...prev, username: 'Please enter a username.' }));
    if (isEmpty(credentials.password)) setError(prev => ({ ...prev, password: 'Please enter a password.' }));

    if (!isEmpty(credentials.username) && !isEmpty(credentials.password))
      try {
        setLoading(true);
        const loginResponse = await login(credentials);
        console.log(loginResponse.status);

        if (loginResponse.data.sessionId && loginResponse.data.expiresIn) {
          setSession({ sessionId: loginResponse.data.sessionId, expiresIn: loginResponse.data.expiresIn });
          setSalesManager({ username: credentials.username, expiresIn: loginResponse.data.expiresIn });
          setSalesManagerId({ id: loginResponse.data.salesManagerId, expiresIn: loginResponse.data.expiresIn });
          dispatchNotification({
            status: 'success',
            title: 'Welcome! Your authentication is complete. Step into the Bespoke experience.'
          });
          dispatchIsAuthenticated(true);
          navigate('/client');
        }
      } catch (error) {
        setError({
          username: 'Invalid username or password. Please try again.',
          password: 'Invalid username or password. Please try again.'
        });
      } finally {
        setLoading(false);
      }
  };

  return (
    <>
      <div className='salesManagerLogin-header'>
        <SVG icon='logo/bespokeliving' />
      </div>
      <div className='salesManagerLogin'>
        <div className='authentication'>
          <span className='authentication-title'>{t('sales_manager_login.authentication')}</span>

          <form className='authentication-form'>
            <TextInput
              label={t('sales_manager_login.username')}
              name='username'
              icon='action/user'
              value={credentials.username}
              onChangeFn={handleChange}
              clearFn={handleClearUsername}
              required
              errorMessage={error.username}
            />

            <PasswordInput
              label={t('sales_manager_login.password')}
              name='password'
              icon='action/key'
              value={credentials.password}
              onChangeFn={handleChange}
              errorMessage={error.password}
            />

            <div className='password-message'>
              <div className='icon'>
                <SVG icon='semantic/info-outline' />
              </div>
              <span>
                {t('sales_manager_login.password_message_regular')}{' '}
                <a
                  href='https://help.salesforce.com/s/articleView?id=xcloud.user_security_token.htm&type=5'
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('sales_manager_login.password_message_underlined')}
                </a>
                .
              </span>
            </div>
          </form>
          <div className='button-container'>
            <Button primary={true} text={t('sales_manager_login.button')} onClickFn={handleLogin} />
          </div>
        </div>
        <div>
          <img src={salesManagerLogin} />
        </div>
      </div>
    </>
  );
};

export default SalesMangerLogin;
