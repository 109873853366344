import { houseTypesAction } from './houseTypes-slice';
import { getHouseTypes } from '@services/houseTypes';

export const setHouseTypes = async dispatch => {
  try {
    const response = await getHouseTypes(true, 'small');
    dispatch(houseTypesAction.setHouseTypes(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
