// src/Portal.js
import React from 'react';
import ReactDOM from 'react-dom';
import './Portal.scss';

const Portal = ({ noBlur, children }) => {
  return ReactDOM.createPortal(
    <div className={`portal-overlay${noBlur ? '' : '-blur'}`}>
      <div className={`portal-content${noBlur ? '' : '-blur'}`}>{children}</div>
    </div>,
    document.body
  );
};

export default Portal;
