// styles
import { SVG } from '@assets/svg';
import './Pill.scss';

const Pill = ({ label, onClickFn }) => {
  return (
    <div className='pill-container'>
      <span className='label'>{label}</span>
      <div className='icon' onClick={onClickFn}>
        <SVG icon='semantic/circle-x' />
      </div>
    </div>
  );
};

export default Pill;
